import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomModal from "./components/CustomModal";
import { FieldArray, Form, Formik } from "formik";
import CustomInput from "../components/CustomInput";
import Editor from "./components/Editor";
import * as Yup from "yup";
import { useApiRequest } from "../hooks/useRequest";
import FileUpload from './components/DocumentUploading';
import { serialize } from 'object-to-formdata';

function Service(props) {
  const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [listService, setListService] = useState([]);
 const [service, setService] = useState({})
  useEffect(() => {
    GetServiceList()
  }, [])

  const GetServiceList = async () => {
    const response = await getRequest(`services?type=${1}`);
    if (response) {
        setListService(response.data)
    }
  }
  const GetService = async (id) => {
    const response = await getRequest(`service/${id}`);
    
    if (response) {
        setService(response.data)
      setShowModalEdit(true)
    }
  }
  const Validation = Yup.object().shape({
    name_uz: Yup.string().required('Заполните поле!'),

  });


  const SaveData = async(values, setValues) => {
      const response = await postRequest("service/create", serialize(values));
      if(response)
      {
          GetServiceList()
          setShowModal(false);
      }
  }


  const [postOne, setPostOne] = useState(null);

  const changeStatus = async(id) => {
    const response = await postRequest("service/change-status", {id});
    if (response) {
      GetServiceList()
    }
    
  }



  const DeletePost = async (id) => {
    const response = await deleteRequest("service/delete/" + id + "");
    if (response) {
      GetServiceList()
    }
  }

  const OpenEditModal = async (items) => {
    GetServiceList(items?.id)
    setPostOne(items)
  }


  return (
    <div className="p-6">
      <div className={'d-flex flex-row align-items-center justify-content-between'}>
        <h1 className="text-2xl font-semibold">Statistikalar</h1>
        <button className={'btn btn-sm btn-primary'}
          onClick={() => setShowModal(true)}
        >
          <FiPlus />
        </button>
      </div>

      <div className={'mt-3'}>
        <div>
          <table className={'table table-striped table-hover'}>
            <thead>
              <tr>
                <th>№</th>
                <th>Nomi</th>
                <th>Qiymat</th>
                <th>Status</th>
                <th width={100}>#</th>
              </tr>
            </thead>
            <tbody>
              {
                listService && listService.map((items, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{items?.name_uz}</td>
                      <td>{items?.value}</td>
                      <td> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" checked={items?.status || false} onChange={(e) => changeStatus(items?.id)} role="switch"/>
                        </div>
                      </td>
                      <td>
                        <div className={'d-flex gap-3'}>
                          <button className={'btn btn-sm btn-outline-secondary'}
                            onClick={() => OpenEditModal(items)}
                          ><FiEdit />
                          </button>
                          <button className={'btn btn-sm btn-outline-danger'}
                            onClick={() => DeletePost(items?.id)}
                          ><FiTrash2 /></button>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <CustomModal
        show={showModal}
        setShow={setShowModal}
        title=""
        fullscreen={true}
      >
        <Formik
          initialValues={{
            name_uz: "",
            name_ru: "",
            name_en: "",
            status: true,
            type: 1
          }}
          validationSchema={Validation}
          onSubmit={(values, { setValues }) => {
            
            SaveData(values, setValues)
          }}

        >
          {({
            handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
          }) => {
            
            return (
              <Form>
                <div>

                  <div className={'py-2 d-flex align-items-end justify-end'}>
                    <button type='submit' className={'btn btn-outline-primary'}>Saqlash</button>
                  </div>


                  <div className={'py-2'}>
                    <div className={'row gap-y-4'}>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Nomi"}
                          label={"Nomi (uz)"}
                          name={"name_uz"}
                          value={values.name_uz || ""}
                          onChange={handleChange}
                          error={errors.name_uz}
                          touched={touched.name_uz}
                        />
                      </div>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Nomi"}
                          label={"Nomi (ru)"}
                          name={"name_ru"}
                          value={values.name_ru}
                          onChange={handleChange}
                          error={errors.name_ru}
                          touched={touched.name_ru}
                        />
                      </div>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Nomi"}
                          label={"Nomi (en)"}
                          name={"name_en"}
                          value={values.name_en}
                          onChange={handleChange}
                          error={errors.name_en}
                          touched={touched.name_en}
                        />
                      </div>
                     
                      </div>
                                <div className={'col-md-4 align-self-center'}>
                                   <div className='d-flex align-items-end h-100'>
                                   <div className="form-control-lg form-check form-switch mb-0">
                                        <input
                                            className="form-check-input"
                                            name='status'
                                            type="checkbox"
                                            id="status"
                                            checked={values?.status || false}
                                            onChange={(e) => setFieldValue('status', e.target.checked)}
                                        />
                                        <label className="form-check-label" for="flexSwitchCheckDefault">Status</label>
                                    </div>
                                   </div>
                                </div>
                            </div>
                     
                  </div>
              </Form>
            )
          }}
        </Formik>
      </CustomModal>
    </div>
  )
}

export default Service;
