import React, { useEffect, useState } from 'react';
import { useApiRequest } from "../hooks/useRequest";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersCarousel = () => {
    const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();
    const [links, setListLinks] = useState([]);
         useEffect(() => {
            getLinks()
          }, [])
        
          const getLinks = async () => {
            const response = await getRequest("links");
            if (response) {
                setListLinks(response.data)
            }
          }
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    return (
        <div className="max-w-12xl mx-auto">

            <Slider {...settings}>
                {links.map((link) => (
                    <div
                        key={link.id}
                        className="flex items-center gap-4"
                    >
                        <a href={link.url} target='_blank' className="w-full">
                            <div className={'bg-white p-2 gap-4 rounded w-[90%] rounded-xl'}>
                                <div className={'text-center flex flex-col items-center'}>
                                <img
                                        src={`https://api.uzdjtsu.uz/storage/${link?.image}`} 
                                        alt={link.name_uz}
                                        className="w-full h-full object-cover rounded-xl"
                                    />
                                    <div className='text-secondary text-decoration-none'>
                                        {link.name_uz}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default PartnersCarousel;
