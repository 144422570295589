import React, { useEffect, useState } from "react";
import {NavLink, useParams, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import Yil from '../images/yil_2024.jpg'
import {FiArrowUpRight, FiCalendar, FiPhone} from "react-icons/fi";
import Rektor from '../images/rektor.png'
import {useApiRequest} from "../hooks/useRequest";
import LocalizedContent from "../components/LocalizedContent";
import Modal from 'react-bootstrap/Modal';

function AboutPage() {
    const {lang, slug } = useParams();
    const location = useLocation();
    const [content, setContent] = useState("");
    const { postRequest, loading, getRequest } = useApiRequest();
    const [postInfo, setPostInfo] = useState([]);
    const [contentInfo, setContentInfo] = useState(null);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showFunc, setShowFunc] = useState(false);
    const [bio, setBio] = useState(false);
    const [func, setFunc] = useState(false);
    const handleClose = () => {
        setShow(false);
    }

    const handleCloseFunc = () => {
        setShowFunc(false);
    }
    // const fullSlug = location?.pathname

    const fullPath = location.pathname.startsWith('/')
        ? location.pathname.substring(4)
        : location.pathname;



    useEffect(() => {
        const supportedLangs = ['uz', 'ru', 'en'];
        if (!supportedLangs.includes(lang)) {
            navigate('/uz/universitet/' + slug, { replace: true });
        }
        getInfo(fullPath)

    }, [slug]);

    const getInfo = async (slug) => {
        const data = {
            slug: slug
        }
        const response = await postRequest("post-info", data,null ,false);
        if(response) {
            setPostInfo(response?.data)

        }
    }

    return (
        <>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-9'}>
                        <h3>
                            {
                                slug === 'universitet-haqida' && <>Universitet haqida</>
                            }
                        </h3>

                        {postInfo[0]?.sub_type === 1 && (
                            <div
                                dangerouslySetInnerHTML={{ __html: postInfo[0].page_content }}
                            />
                        )}


                        {postInfo[0]?.sub_type === 4 && (

                                postInfo?.map((items, index) => {
                                    return(
                                        <>
                                            <div className={'grid grid-cols-4 gap-6 border border-primary rounded-xl p-3 border-main-bg mb-6 relative'}>
                                                <div style={{height: 280}} className={'col-span-1'}>
                                                    <img src={`https://api.uzdjtsu.uz/storage/${items?.member_photo}`} alt={""}
                                                         className="h-full w-full object-cover rounded-xl border mt-1"

                                                    />
                                                </div>
                                                <div className={'col-span-3'}>
                                                    <h4 className={'text-xl font-bold'}>
                                                        <LocalizedContent
                                                            lang={lang}
                                                            uzContent={items?.member_name}
                                                            ruContent={items?.member_name_ru}
                                                            enContent={items?.member_name_en}
                                                        />

                                                    </h4>
                                                    <h4 className={'py-3'}>
                                                        <LocalizedContent
                                                            lang={lang}
                                                            uzContent={items?.member_deputy_name}
                                                            ruContent={items?.member_deputy_name_ru}
                                                            enContent={items?.member_deputy_name_en}
                                                        />
                                                    </h4>
                                                    <div className={'mt-2'}>
                                                        <p className={'text-gray-500 flex items-center gap-2'}>
                                                    <span>
                                                        <FiCalendar/>
                                                    </span>
                                                            Qabul kunlari
                                                        </p>
                                                        <p>
                                                            <LocalizedContent
                                                                lang={lang}
                                                                uzContent={items?.member_content}
                                                                ruContent={items?.member_content_ru}
                                                                enContent={items?.member_content_en}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className={'mt-2'}>
                                                        <p className={'text-gray-500 flex items-center gap-2'}>
                                                    <span>
                                                        <FiPhone/>
                                                    </span>
                                                            Telefon raqami
                                                        </p>
                                                        <p>{items?.member_phone}</p>
                                                    </div>
                                                    <div className={'mt-2'}>
                                                        <p className={'text-gray-500 flex items-center gap-2'}>
                                                    <span>
                                                        <FiPhone/>
                                                    </span>
                                                            E-pochta manzili
                                                        </p>
                                                        <p>{items?.member_email}</p>
                                                    </div>

                                                    <div className={'mt-1 flex items-center gap-2 justify-end absolute bottom-3 right-3'}>
                                                        <button
                                                            onClick={() => {
                                                                setShow(true)
                                                                setBio(
                                                                    <LocalizedContent
                                                                        lang={lang}
                                                                        uzContent={items?.member_bio}
                                                                        ruContent={items?.member_bio_ru}
                                                                        enContent={items?.member_bio_en}
                                                                    />
                                                                )
                                                            }}
                                                            className={'bg-main-bg rounded-xl p-2 text-white flex items-center gap-2'}>
                                                            Tarjimayi hol
                                                            <span>
                                                        <FiArrowUpRight/>
                                                    </span>
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setShowFunc(true)
                                                                setFunc(
                                                                    <LocalizedContent
                                                                        lang={lang}
                                                                        uzContent={items?.member_function}
                                                                        ruContent={items?.member_function_ru}
                                                                        enContent={items?.member_function_en}
                                                                    />
                                                                )
                                                            }}
                                                            className={'bg-main-bg rounded-xl p-2 text-white flex items-center gap-2'}>
                                                            Vazifalari
                                                            <span>
                                                        <FiArrowUpRight/>
                                                    </span>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>


                                        </>
                                    )
                                })


                        )}


                    </div>
                    <div className={'col-md-3'}>
                        <div className={'border rounded-xl'}>
                            <div>
                                <ul className={'sidebar-menu'}>
                                    <li>
                                        <NavLink to="/pages/universitet-haqida" className={'active'}>JTSU
                                            haqida</NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Tuzilma</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Rekvizitlar</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Meʼyoriy hujjatlar</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">O’quv binolari</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Mashhur bitiruvchilar</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Universitet kengashi</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Rektorat</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Filiallar</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tashkilot/rahbariyat">Fakultet va kafedralar </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className={'relative mt-6'}>
                            <img src={Yil} alt={""} className={'rounded-xl'} style={{height: 300}}/>
                            <div className={'yil'}>
                                2024-yil "Yoshlar va biznesni qo‘llab-quvvatlash yili
                            </div>
                        </div>

                        <div className={'mt-6'}>
                            <div className={'bg-white rounded-xl p-3 border'}>
                                <h4 className={'text-xl'}>So'ngi yangiliklar</h4>
                                <div className={'py-3 border-b'}>
                                    <p>Yosh olimlar va talabalarni ilmiy faoliyatga keng ravishda jalb qilishga
                                        xizm...</p>
                                    <p className={'text-indigo-400'}>14.12.2024</p>
                                </div>

                                <div className={'py-3 border-b'}>
                                    <p>Yosh olimlar va talabalarni ilmiy faoliyatga keng ravishda jalb qilishga
                                        xizm...</p>
                                    <p className={'text-indigo-400'}>14.12.2024</p>
                                </div>


                                <div className={'py-3 border-b'}>
                                    <p>Yosh olimlar va talabalarni ilmiy faoliyatga keng ravishda jalb qilishga
                                        xizm...</p>
                                    <p className={'text-indigo-400'}>14.12.2024</p>
                                </div>

                                <div className={'py-3 border-b'}>
                                    <p>Yosh olimlar va talabalarni ilmiy faoliyatga keng ravishda jalb qilishga
                                        xizm...</p>
                                    <p className={'text-indigo-400'}>14.12.2024</p>
                                </div>

                                <div className={'py-3 border-b'}>
                                    <p>Yosh olimlar va talabalarni ilmiy faoliyatga keng ravishda jalb qilishga
                                        xizm...</p>
                                    <p className={'text-indigo-400'}>14.12.2024</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size={"xl"}>
                <Modal.Header closeButton>
                    <Modal.Title>Biografiya</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {bio}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            <Modal show={showFunc} onHide={handleCloseFunc} size={"xl"}>
                <Modal.Header closeButton>
                    <Modal.Title>Funksiya va vazifalari</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {func}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </>
    );
}

export default AboutPage;
