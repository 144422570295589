import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomModal from "./components/CustomModal";
import { FieldArray, Form, Formik } from "formik";
import CustomInput from "../components/CustomInput";
import Editor from "./components/Editor";
import * as Yup from "yup";
import { useApiRequest } from "../hooks/useRequest";
import FileUpload from './components/DocumentUploading';
import { serialize } from 'object-to-formdata';

function Gallery(props) {
  const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();
  const { token } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [listGallery, setListGallery] = useState([]);
 const [gallery, setGallery] = useState({})
  useEffect(() => {
    GetGalleryList()
  }, [])

  const GetGalleryList = async () => {
    const response = await getRequest("galleries");
    if (response) {
      setListGallery(response.data)
    }
  }
  const GetGallery = async (id) => {
    const response = await getRequest(`gallery/${id}`);
    
    if (response) {
      setGallery(response.data)
      setShowModalEdit(true)
    }
  }
  const Validation = Yup.object().shape({
    name_uz: Yup.string().required('Заполните поле!'),
    image: Yup.array().required('Заполните поле!')
  });


  const SaveData = async(values, setValues) => {
      const response = await postRequest("gallery/create", serialize({...values, image:values?.image?.[0]}, {indices:true,allowEmptyArrays: true}));
      if(response)
      {
          GetGalleryList()
          setShowModal(false);
      }
  }

  const UpdatePost = async (values, setValues) => {

      const response = await putRequest(`gallery/edit/${postOne?.id}`, values);
      if(response)
      {
          setShowModalEdit(false)
          GetGalleryList()
      }
  }
  const changeStatus = async(id) => {
    const response = await postRequest("gallery/change-status", {id});
    if (response) {
      GetGalleryList()
    }
    
  }

  const [postOne, setPostOne] = useState(null);



  const DeletePost = async (id) => {
    const response = await deleteRequest("gallery/delete/" + id + "");
    if (response) {
      GetGalleryList()
    }
  }

  const OpenEditModal = async (items) => {
    GetGallery(items?.id)
    setPostOne(items)
  }


  return (
    <div className="p-6">
      <div className={'d-flex flex-row align-items-center justify-content-between'}>
        <h1 className="text-2xl font-semibold">Hamkorlar</h1>
        <button className={'btn btn-sm btn-primary'}
          onClick={() => setShowModal(true)}
        >
          <FiPlus />
        </button>
      </div>

      <div className={'mt-3'}>
        <div>
          <table className={'table table-striped table-hover'}>
            <thead>
              <tr>
                <th>№</th>
                <th>Nomi</th>
                <th>Status</th>
                <th width={100}>#</th>
              </tr>
            </thead>
            <tbody>
              {
                listGallery && listGallery.map((items, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{items?.name_uz}</td>
                      <td> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" checked={items?.status || false} onChange={(e) => changeStatus(items?.id)} role="switch"/>
                        </div>
                      </td>
                      <td>
                        <div className={'d-flex gap-3'}>
                          <button className={'btn btn-sm btn-outline-secondary'}
                            onClick={() => OpenEditModal(items)}
                          ><FiEdit />
                          </button>
                          <button className={'btn btn-sm btn-outline-danger'}
                            onClick={() => DeletePost(items?.id)}
                          ><FiTrash2 /></button>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>

      <CustomModal
        show={showModal}
        setShow={setShowModal}
        title=""
        fullscreen={true}
      >
        <Formik
          initialValues={{
            name_uz:  "",
            name_ru:  "",
            name_en: "",
            image:  [],
            status:true,
            type: ""
          }}
          validationSchema={Validation}
          onSubmit={(values, { setValues }) => {
            
            SaveData(values, setValues)
          }}

        >
          {({
            handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
          }) => {

            return (
              <Form>
                <div>

                  <div className={'py-2 d-flex align-items-end justify-end'}>
                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                  </div>


                  <div className={'py-2'}>
                    <div className={'row gap-y-4'}>
                        <div className={'col-md-4'}>
                                    <div className={'flex items-center gap-3'}>
                                        <div>
                                            <label>Kategoriya tanlang</label>
                                        </div>
                                        <div>
                                            {errors && touched ? (
                                                <div style={{marginLeft: 5}}>
                                                    <div style={{
                                                        color: 'red',
                                                        fontSize: 9
                                                    }}>{errors.page_menu_id}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <select className={'form-select'} onChange={(e) => {
                                        setFieldValue("type", e.target.value)
                                    }}>
                                        <option>---</option>
                                        <option value={1}>Video</option>
                                        <option value={2}>Foto</option>                                    
                                    </select>
                            </div>
                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Nomi"}
                          label={"Nomi (uz)"}
                          name={"name_uz"}
                          value={values.name_uz || ""}
                          onChange={handleChange}
                          error={errors.name_uz}
                          touched={touched.name_uz}
                        />
                      </div>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Nomi"}
                          label={"Nomi (ru)"}
                          name={"name_ru"}
                          value={values.name_ru}
                          onChange={handleChange}
                          error={errors.name_ru}
                          touched={touched.name_ru}
                        />
                      </div>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Nomi"}
                          label={"Nomi (en)"}
                          name={"name_en"}
                          value={values.name_en}
                          onChange={handleChange}
                          error={errors.name_en}
                          touched={touched.name_en}
                        />
                      </div>
                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Tarif"}
                          label={"Tarif (uz)"}
                          name={"desc_uz"}
                          value={values.desc_uz}
                          onChange={handleChange}
                          error={errors.desc_uz}
                          touched={touched.desc_uz}
                        />
                      </div>
                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Tarif"}
                          label={"Tarif (ru)"}
                          name={"desc_ru"}
                          value={values.desc_ru}
                          onChange={handleChange}
                          error={errors.desc_ru}
                          touched={touched.desc_ru}
                        />
                      </div>
                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Tarif"}
                          label={"Tarif (en)"}
                          name={"desc_en"}
                          value={values.desc_en}
                          onChange={handleChange}
                          error={errors.desc_en}
                          touched={touched.desc_en}
                        />
                      </div>
                        {
                            values?.type === '1' ? (
                                <div className={'col-md-4'}>
                                                <CustomInput
                                                placeholder={"Url"}
                                                label={"Url"}
                                                name={"url"}
                                                value={values.url}
                                                onChange={handleChange}
                                                error={errors.url}
                                                touched={touched.url}
                                                />
                                            </div>
                            ) : null
                        }
                      
                        <div className={'col-md-4 align-self-center'}>
                            <div className='d-flex align-items-end h-100'>
                            <div className="form-control-lg form-check form-switch mb-0">
                                <input
                                    className="form-check-input"
                                    name='status'
                                    type="checkbox"
                                    id="status"
                                    checked={values?.status || false}
                                    onChange={(e) => setFieldValue('status', e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Status</label>
                            </div>
                            </div>
                        </div>
                     

                      <div className={'col-md-12'}>
                        <div className='d-flex gap-3'>
                          {
                            values?.image?.length ? null : (
                          <div className='flex-shrink-0' style={{width: 400}}>
                            <FileUpload
                            formats={[".jpg", ".jpeg", ".png",]}
                              value={values?.image || []}
                              name={'image'}
                              onChange={(files) => {
                                setFieldValue('image', files);
                              }}
                              isMulti={false}
                              labelClassName="cursor-pointer"
                            >
                            </FileUpload>
                          </div>
                            )
                          }
                          <div className='flex-grow-1'>
                            <FieldArray name="image">
                              {({ insert, remove, push }) => (
                                <div>
                                  {values.image.length > 0 &&
                                    values.image.map((image, index) => (
                                      <div className="row" key={index}>
                                        <div className='col-2'>
                                          <div className='position-relative'>
                                          <img src={URL.createObjectURL(image)} alt='img' />
                                          <button
                                            type="button"
                                            className="secondary position-absolute top-0 end-0 btn bg-secondary rounded-pill text-white opacity-50"
                                            onClick={() => remove(index)}
                                          >
                                            X
                                          </button>
                                          </div>

                                        </div>
                                      </div>
                                    ))}                               
                                </div>
                              )}
                            </FieldArray>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </CustomModal>

      
      <CustomModal
        show={showModalEdit}
        setShow={setShowModal}
        title=""
        fullscreen={true}
      >
        <Formik
          initialValues={{
            name_uz:  "",
            name_ru: "",
            name_en: "",
            image:  []
          }}
          validationSchema={Validation}
          onSubmit={(values, { setValues }) => {
            
            SaveData(values, setValues)
          }}

        >
          {({
            handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
          }) => {
            console.log(values, 'value', errors);

            return (
              <Form>
                <div>

                  <div className={'py-2 d-flex align-items-end justify-end'}>
                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                  </div>


                  <div className={'py-2'}>
                    <div className={'row gap-y-4'}>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Hamkor nomi"}
                          label={"Hamkor nomi (uz)"}
                          name={"name_uz"}
                          value={values.name_uz || ""}
                          onChange={handleChange}
                          error={errors.name_uz}
                          touched={touched.name_uz}
                        />
                      </div>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Hamkor nomi"}
                          label={"Hamkor nomi (ru)"}
                          name={"name_ru"}
                          value={values.name_ru}
                          onChange={handleChange}
                          error={errors.name_ru}
                          touched={touched.name_ru}
                        />
                      </div>

                      <div className={'col-md-4'}>
                        <CustomInput
                          placeholder={"Hamkor nomi"}
                          label={"Hamkor nomi (en)"}
                          name={"name_en"}
                          value={values.name_en}
                          onChange={handleChange}
                          error={errors.name_en}
                          touched={touched.name_en}
                        />
                      </div>

                      <div className={'col-md-12'}>
                        <div className='d-flex gap-3'>
                          {
                            values?.image?.length ? null : (
                          <div className='flex-shrink-0' style={{width: 400}}>
                            <FileUpload
                            formats={[".jpg", ".jpeg", ".png",]}
                              value={[]}
                              name={'image'}
                              onChange={(files) => {
                                setFieldValue('image', files);
                              }}
                              isMulti={false}
                              labelClassName="cursor-pointer"
                            >
                            </FileUpload>
                          </div>
                            )
                          }
                          <div className='flex-grow-1'>
                            {
                              values?.image?.length ? (
                                <FieldArray name="image">
                                {({ insert, remove, push }) => (
                                  <div>
                                    {values.image.length > 0 &&
                                      values.image.map((image, index) => (
                                        <div className="row" key={index}>
                                          <div className='col-2'>
                                            <div className='position-relative'>
                                            <img src={URL.createObjectURL(image)} alt='img' />
                                            <button
                                              type="button"
                                              className="secondary position-absolute top-0 end-0 btn bg-secondary rounded-pill text-white opacity-50"
                                              onClick={() => remove(index)}
                                            >
                                              X
                                            </button>
                                            </div>
  
                                          </div>
                                        </div>
                                      ))}                               
                                  </div>
                                )}
                              </FieldArray>
                              ) : (
                                <div>
                                  <img src={`https://api.uzdjtsu.uz/storage/${gallery?.image}`} alt='img' />
                                </div>
                              )
                            }
                           
                          </div>
                        </div>

                        {/* <label>Maqola asosiy rasmi</label> */}
                        {/* <div><input type={"file"} onChange={(e) => handleFileUpload(e, setFieldValue)}/></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </CustomModal>

    </div>
  )
}

export default Gallery;
