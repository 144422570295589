import React, {useEffect} from "react";
import { Outlet, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";
import MenuMain from "./MenuMain";

function Layout({children}) {
    const { lang = "uz" } = useParams();
    const hideSidebarRoutes = ["/", "/contact"];
    const showSidebar = !hideSidebarRoutes.includes(window.location.pathname);


    return (
        <div>

           <Header/>

            <Menu/>


            <div>

                <main className={'mt-8'}>
                    <Outlet/>
                </main>


            </div>

            {/* Footer */}
            <Footer/>
        </div>
    );
}

export default Layout;
