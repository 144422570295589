import React from 'react';

const InputField = ({
                        label,
                        name,
                        value,
                        onChange,
                        onBlur,
                        error,
                        touched,
                        type = 'text',
                        placeholder,
                        isTextarea = false
                    }) => {
    return (
        <div>
            {/* Label va Error */}
            <div className="d-flex align-items-center mb-2 justify-between">
                <label className="block text-gray-700 font-medium" htmlFor={name}>
                    {label}
                </label>
                {error && touched && (
                    <div style={{ marginLeft: 5 }}>
                        <div style={{ color: 'red', fontSize: 9 }}>{error}</div>
                    </div>
                )}
            </div>

            {/* Input yoki Textarea */}
            {isTextarea ? (
                <textarea
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    rows="4"
                />
            ) : (
                <input
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    type={type}
                />
            )}
        </div>
    );
};

export default InputField;
