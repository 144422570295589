import React, {useEffect, useState} from "react";
import NewsImg from '../images/elon_1.png';
import {useApiRequest} from "../hooks/useRequest";



export default function Tabs() {
    const { postRequest, loading, getRequest } = useApiRequest();
    const [activeTab, setActiveTab] = useState();

     const GetGallery = async (type) => {
            const response = await getRequest(`galleries`, {type, status:true}, false);
            if(response){
                setActiveTab(response?.data)
            }
        }
        useEffect(()=> {
            GetGallery(1)
        },[])

        const tabs = [
            {
                name: "Videogalereya",
                type: 1
            },
            {
                name: "Fotogalereya",
                type:2
            }
        ]

    return (
        <div className="w-full mt-10">
            {/* Tabs Header */}
            <div className="border-b border-gray-300 flex">
            {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => GetGallery(tab?.type)}
                        className={`w-1/4 py-2 text-2xl text-gray-600 text-left font-semibold hover:text-gray-500 transition-colors ${
                            tab?.type === activeTab?.[0]?.type
                                ? "border-b-2 border-blue-500 text-black"
                                : ""
                        }`}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>

            <div className="mt-4">
                {
                    activeTab?.length ?

                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                  {
                       activeTab.map((content, key)=> {

                        return (
                            <>
                                         {<li key={key}
                                                className="p-4 rounded-lg hover:shadow-lg transition-shadow">
                                                <div>
                                                <img src={`https://api.uzdjtsu.uz/storage/${content?.image}`} />
                                                </div>
                                                <h3 className="text-lg font-semibold text-gray-800 mt-2">
                                                    {content.post_title}
                                                </h3>
                                                <p className="text-gray-600">
                                                    {content.post_desc}
                                                </p>
                                            </li> } 
                            </>
                        )
                    }) 
                  }
                    </ul>
                    
                    : (
                        <div className={'bg-white'}>
                                
                                    </div>
                    )
                }
                {/* {activeTab.map(
                    (tab) =>
                        activeTab === tab.id && (
                            <div key={tab.id}>

                                
                            </div>
                        )
                )} */}
            </div>
        </div>
    );
}
