import { createSlice } from "@reduxjs/toolkit";

const savedToken = localStorage.getItem("token");


const initialState = {
    token: savedToken ? savedToken : null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload.token;
            localStorage.setItem("token", action.payload.token);

        },
        logout: (state) => {
            state.token = null;
            localStorage.removeItem("token");

        },
    },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
