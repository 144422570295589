import React from "react";
import { Modal, Button } from "react-bootstrap";

function CustomModal({ show, setShow, title, children, fullscreen = false }) {
    return (
        <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

export default CustomModal;
