function ContactPage() {
    return (
        <>
          <div className={'container'}>
              <div className={'row'}>
                  <div className={'col-md-12'}>
                      <h5 className={'text-center'}>O'zbekiston Davlat Jismoniy-tarbiya va sport universiteti - Xorijiy abiturientlardan onlayn ariza qabul qilish sayti</h5>
                      <p className={'fw-bold text-center mt-3'}>Ma'lumotlaringizni to'ldiring ! </p>
                  </div>
              </div>
              <div className={'row mt-6'}>
                  <div className={'col-md-6'}>
                      <div className={'mb-2'}>
                          <label className={'mb-2'}>F.I.SH (familya, ism, sharifingizni to'liq yozing)</label>
                          <input
                              className={'form-control'}
                          />
                      </div>
                      <div className={'row'}>
                          <div className={'col-md-3'}>
                              <label className={'mb-2'}>Tug'ilgan kuningiz</label>
                              <input
                                  className={'form-control'}
                              />
                          </div>
                          <div className={'col-md-9'}>
                              <div className={'mb-2'}>
                                  <label className={'mb-2'}>Qaysi davlat fuqorosisiz va tug'ulgan joyingiz (To'liq holda
                                      yozing!)</label>
                                  <input
                                      className={'form-control'}
                                  />
                              </div>
                          </div>

                      </div>
                      <div className={'row'}>
                          <div className={'col-md-12'}>
                              <label className={'mb-2'}>Pasport ma'lumotlari</label>
                          </div>
                          <div className={'col-md-3'}>
                              <input className={'form-control'} placeholder={"Seriyasi"}/>
                          </div>
                          <div className={'col-md-3'}>
                              <input className={'form-control'} placeholder={"Raqami"}/>
                          </div>
                          <div className={'col-md-3'}>
                              <input className={'form-control'} placeholder={"Berilgan sanasi"}/>
                          </div>
                      </div>
                      <div className={'row mt-3'}>
                          <div className={'col-md-12'}>
                              <label className={'mb-2'}>Kim tomondan berilgan</label>
                              <input className={'form-control'}/>
                          </div>
                      </div>
                      <div className={'row mt-3'}>
                          <div className={'col-md-3'}>
                              <label className={'mb-2'}>Telefon raqamingiz</label>
                              <input className={'form-control'}/>
                          </div>
                          <div className={'col-md-9'}>
                              <label className={'mb-2'}>Doimiy yashash manzilingiz</label>
                              <input className={'form-control'}/>
                          </div>
                      </div>
                      <div className={'row mt-3'}>
                          <div className={'col-md-12'}>
                              <div className={'bg-gray-100 rounded p-3'}>
                                  <label className={'mb-2'}>Pasport oldi va orqa tomoni</label>
                                  <input className={'form-control'} type={'file'}/>
                              </div>
                          </div>
                      </div>
                      <div className={'row mt-3'}>
                          <div className={'col-md-12'}>
                              <label className={'mb-2'}>Otangiz haqida yozing</label>
                              <textarea className={'form-control'}></textarea>
                          </div>
                      </div>
                      <div className={'row mt-3'}>
                          <div className={'col-md-12'}>
                              <label className={'mb-2'}>Onangiz haqida yozing</label>
                              <textarea className={'form-control'}></textarea>
                          </div>
                      </div>
                      <div className={'row mt-3'}>
                          <div className={'col-md-12'}>
                              <label className={'mb-2'}>Agar sog‘lig‘ingizda muammolar bo‘lsa, iltimos, ma’lumotlarni kiriting</label>
                              <textarea className={'form-control'}></textarea>
                          </div>
                      </div>
                  </div>


                  <div className={'col-md-6'}>
                      <div className={'row'}>
                          <div className={'col-md-12'}>
                              <label className={'mb-2'}>JTSU oliy ta'lim muassasasida o'qishni istagan yo'nalish shifri
                                  va nomini yozing</label>

                          </div>

                          <div className={'col-md-6'}>
                              <input className={'form-control'} placeholder={'Shifrni yozing'}/>
                          </div>

                          <div className={'col-md-6'}>
                              <input className={'form-control'} placeholder={"Yo'nalish nomini yozing"}/>
                          </div>

                      </div>

                      <div className={'row mt-2'}>
                          <div className={'col-md-12'}>
                              <label className={'mb-2'}>Ta'lim shaklini va boshqa ma'lumotlarni tanlang</label>
                          </div>
                          <div className={'row'}>
                              <div className={'col-md-4'}>
                                  <select className={'form-select'}>
                                      <option value={""}>Ta'lim shaklini tanlang</option>
                                  </select>
                              </div>
                              <div className={'col-md-4'}>
                                  <select className={'form-select'}>
                                      <option value={""}>Jinsingizni tanlang</option>
                                  </select>
                              </div>
                              <div className={'col-md-4'}>
                                  <select className={'form-select'}>
                                      <option value={""}>Ma'lumotingizni tanlang</option>
                                  </select>
                              </div>
                          </div>

                          <div className={'row mt-3'}>
                              <div className={'col-md-12'}>
                                  <div className={'bg-gray-100 rounded p-3'}>
                                      <label className={'mb-2'}>Ma'lumotingizni tasdiqlaydigan hujjatni yuklang</label>
                                      <input className={'form-control'} type={'file'}/>
                                  </div>
                              </div>
                          </div>

                          <div className={'row mt-2'}>
                              <div className={'col-md-12'}>
                                  <label className={'mb-2'}>Suxbatni qaysi tilda olib borishni istaysiz?</label>
                                  <select className={'form-select'}>
                                      <option value={""}>Tanlang</option>
                                  </select>
                              </div>
                          </div>

                          <div className={'row mt-2'}>
                              <div className={'col-md-12'}>
                                  <label className={'mb-2'}> Qaysi ta'lim muassasasini bitirgansiz? </label>
                                  <input className={'form-control'}/>
                              </div>
                          </div>

                          <div className={'row mt-1'}>
                              <div className={'col-md-12'}>
                                  <label className={'mb-2'}> Ta'lim muassasasini qaysi yili va qaysi yo'nalishda
                                      bitirgansiz? </label>
                                  <div className={'row mt-1'}>
                                      <div className={'col-md-6'}>
                                          <select className={'form-select'}>
                                              <option value={""}>Yilni tanlang</option>
                                          </select>
                                      </div>
                                      <div className={'col-md-6'}>
                                          <input className={'form-control'} placeholder={"Yo'nalish nomini yozing"}/>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className={'row mt-1'}>
                              <div className={'col-md-12'}>
                                  <label className={'mb-2'}>Diplom seriyasi va raqami</label>
                                  <div className={'row mt-1'}>
                                      <div className={'col-md-6'}>
                                          <input className={'form-control'} placeholder={"Seriyasini yozing"}/>
                                      </div>
                                      <div className={'col-md-6'}>
                                          <input className={'form-control'} placeholder={"Raqamini yozing"}/>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className={'row mt-2'}>
                              <div className={'col-md-12'}>
                                  <label className={'mb-2'}> Siz qaysi chet tillarini bilasiz va qay darajada? </label>
                                  <input className={'form-control'} placeholder={"Masalan: Ingliz tili Beginner"}/>
                              </div>
                          </div>

                          <div className={'row mt-2'}>
                              <div className={'col-md-12'}>
                                  <label className={'mb-2'}> Ushbu oliy ta’lim muassasasiga kirish oldidan ishlagan
                                      tashkilot va ishlagan muddatingiz (agar mavjud bo‘lsa) </label>
                                  <textarea className={'form-control'}></textarea>
                              </div>
                          </div>

                          <div className={'row mt-2'}>
                              <div className={'col-md-12'}>
                                  <input type={"checkbox"}/>
                                  <label className={'mx-2'}>Barcha ma'lumotlaringiz to'g'rimi</label>
                              </div>
                          </div>

                          <div className={'row mt-2'}>
                              <div className={'col-md-12'}>
                                 <button className={'bg-blue-500 p-2 w-1/2 rounded-xl text-white font-bold'}>Jo'natish</button>
                              </div>
                          </div>

                      </div>

                  </div>

              </div>

              <div style={{height: 100}}></div>

          </div>
        </>
    )
}

export default ContactPage;
