import React from 'react';

const InputField = ({ label, name, value, onChange, onBlur, error, touched, typeInput = 'text' }) => {
    return (
        <div>
            <div className="d-flex align-items-center mb-1">

                <label htmlFor={name}>{label}</label>
                {error && touched ? (
                    <div style={{ marginLeft: 5 }}>
                        <div style={{ color: 'red', fontSize: 9 }}>{error}</div>
                    </div>
                ) : null}

            </div>

            <input
                id={name}
                name={name}
                className="form-control"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type={typeInput}
            />
        </div>
    );
};

export default InputField;
