import Logo from "../images/footer_logo.png";
import React from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import InputField from "../components/InputField";
import { InputMask } from '@react-input/mask';
import { PatternFormat } from 'react-number-format';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactPage() {

    const SendApp = async (values, setValues) => {
        try {
            const response = await axios.post("https://api.uzdjtsu.uz/api/murojaat", values);
            setValues({
                f_i_sh: "",
                birth_day: "",
                email: "",
                type: "",
                phone_number: "",
                murojaat_text: "",
                offerta: false
            });
            toast.success("Ma'lumot muvaffaqiyatli yuborildi!", { position: "top-right" });
        }
        catch (error) {
            toast.error(error.response?.data?.message || "Xatolik yuz berdi!", { position: "top-right" });
        }
    }

    const AppType = [
        {
            label: "Kursdan kursga qolib ketish",
            value: 1
        },
        {
            label: "Talabalar safidan chetlashtirilgan",
            value: 2
        },
        {
            label: "Universitetga qabul imtihon natijalaridan norozi",
            value: 3
        },
        {
            label: "Universitetning TTJsida Suv (elektr, sovuq suv, issiq suv) yo‘qligi",
            value: 4
        },
        {
            label: "Universitet talabalari yuzasidan (ijara pulini to‘lamaslik va h.k)",
            value: 5
        }
    ]


    const ValidationAddService = Yup.object().shape({
        f_i_sh: Yup.string().required('Заполните поле!'),
        birth_day: Yup.string().required('Заполните поле!'),
        email: Yup.string().required('Заполните поле!'),
        phone_number: Yup.string()
            .required('Заполните поле!'),
        murojaat_text: Yup.string()
            .min(50)
            .required('Заполните поле!'),
        offerta: Yup.boolean().oneOf([true], "Oferta qabul qilish shart!"),
        type: Yup.string().required('Заполните поле!'),
    });

    return (
        <>
            <ToastContainer />
            <div className={'container'}>
              <div className={'row'}>
                  <div className={'col'}>
                      <h5 className={'text-center'}>Rektor virtual qabulxonasiga xush kelibsiz!</h5>
                  </div>

                  <Formik
                      initialValues={{
                          f_i_sh: "",
                          birth_day: "",
                          email: "",
                          type: "",
                          phone_number: "",
                          murojaat_text: "",
                          offerta: false

                      }}
                      validationSchema={ValidationAddService}
                      onSubmit={(values, {setValues}) => {
                          SendApp(values, setValues)
                      }}

                  >
                      {({
                            handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                        }) => (
                          <Form>

                  <>

                  <div className={'mt-6 row'} style={{marginTop: 50, marginBottom: 50}}>
                      <div className={'col-md-6'}>
                          <div className={'my-3'}>

                              <InputField
                                  value={values.f_i_sh}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.f_i_sh}
                                  touched={touched.f_i_sh}
                                  label={"F.I.O*"}
                                  name={"f_i_sh"}
                              />
                          </div>
                          <div className={'my-3'}>

                              <InputField
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.email}
                                  touched={touched.email}
                                  label={"Email Pochta*"}
                                  name={"email"}
                              />
                          </div>
                          <div className={'my-3'}>
                              <div className={'d-flex align-items-center mb-1'}>
                                  <label>Telefon raqam <sup>*</sup></label>
                                  {errors && touched ? (
                                      <div style={{marginLeft: 5}}>
                                          <div style={{color: 'red', fontSize: 9}}>{errors.phone_number}</div>
                                      </div>
                                  ) : null}
                                    </div>
                                        <PatternFormat format="+998 (##) ### ## ##"
                                             className={'form-control'}
                                             name={"phone_number"}
                                                       value={values.phone_number}
                                             onChange={handleChange}
                                        />


                          </div>
                      </div>
                      <div className={'col-md-6'}>
                          <div className={'my-3'}>

                              <InputField
                                  value={values.birth_day}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.birth_day}
                                  touched={touched.birth_day}
                                  label={"Tug'ilgan sana*"}
                                  name={"birth_day"}
                                  typeInput="date"
                              />
                          </div>
                          <div className={'my-3'}>
                              <div className={'d-flex align-items-center mb-1'}>
                                  <label>Murojaat turi <sup>*</sup></label>
                                  {errors && touched ? (
                                      <div style={{ marginLeft: 5 }}>
                                          <div style={{ color: 'red', fontSize: 9 }}>{errors.type}</div>
                                      </div>
                                  ) : null}
                              </div>
                              <select className={'form-select'}
                               onChange={(e) => setFieldValue("type", e.target.value)}
                              >
                                  <option value={""}>Tanlang</option>
                                  {
                                      AppType.map((items, index) => {
                                          return (
                                              <option key={items.value} value={items.value}>{items.label}</option>
                                          )
                                      })
                                  }
                              </select>
                          </div>

                      </div>
                  </div>

                  <div className={'my-2'}>
                      <div className={'mb-4'}>
                          <input type={"checkbox"} onChange={handleChange} name={"offerta"} value={values.offerta} />
                          <span className={'mx-2 '}>Murojaat yuborish qoidalarini qabul qildim</span>
                          {errors && touched ? (
                              <div style={{marginLeft: 5}}>
                                  <div style={{color: 'red', fontSize: 9}}>{errors.offerta}</div>
                              </div>
                          ) : null}
                      </div>
                      <div className={'d-flex align-items-center mb-1'}>
                          <label>Murojaat to'liq matni (Kamida 50 ta harf)</label>
                          {errors && touched ? (
                              <div style={{marginLeft: 5}}>
                                  <div style={{color: 'red', fontSize: 9}}>{errors.murojaat_text}</div>
                              </div>
                          ) : null}
                      </div>
                      <textarea className={'form-control'}
                       value={values.murojaat_text}
                       name={"murojaat_text"}
                                onChange={handleChange}
                      ></textarea>
                  </div>

                  <div className={'my-2'}>
                      <button className={'btn btn-primary'} type={"submit"} onClick={handleSubmit}>Jo'natish</button>
                  </div>

                  </>
                          </Form>
                          )}
                  </Formik>

              </div>
          </div>
        </>
    )
}

export default ContactPage;
