"use client";

import React, {useEffect, useState} from "react";
import { REACT_APP_LINK } from '../config'

import {useApiRequest} from "../hooks/useRequest";




export default function Tabs() {
    const { postRequest, loading, getRequest } = useApiRequest();
    
    const [activeTab, setActiveTab] = useState([]);
    
    const GetElon = async (id) => {
        const response = await getRequest(`post/${id}`, {}, false);
        if(response){
            setActiveTab(response?.data)
        }
    }
    useEffect(()=> {
        GetElon(39)
    },[])
 
const tabs = [
    {
        name: "E'lonlar",
        id: 39
    },
    {
        name: "Tanlovlar",
        id: 40
    }
]

    
    return (
        <div className="w-full mt-10">
            <div className="border-b border-gray-300 flex">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        onClick={() => GetElon(tab.id)}
                        className={`w-1/4 py-2 text-2xl text-gray-600 text-left font-semibold hover:text-gray-500 transition-colors ${
                            activeTab === tab.id
                                ? "border-b-2 border-blue-500 text-black"
                                : ""
                        }`}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>

            {/* Tabs Content */}
            <div className="mt-4">
                {
                    activeTab?.length ?

                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                  {
                       activeTab.map((content, key)=> {

                        return (
                            <>
                                         {<li key={key}
                                                className="p-4 rounded-lg hover:shadow-lg transition-shadow">
                                                <div>
                                                <img src={`https://api.uzdjtsu.uz/storage/${content?.post_image}`} />
                                                </div>
                                                <h3 className="text-lg font-semibold text-gray-800 mt-2">
                                                    {content.post_title}
                                                </h3>
                                                <p className="text-gray-600">
                                                    {content.post_desc}
                                                </p>
                                            </li> } 
                            </>
                        )
                    }) 
                  }
                    </ul>
                    
                    : (
                        <div className={'bg-white'}>
                                        {/* <div>
                                            <img src={NewsImg} alt={"s"}/>
                                        </div>
                                        <h3 className="text-lg font-semibold text-gray-800">
                                            {tab.content.title}
                                        </h3>
                                        <p className="text-gray-600">
                                            {tab.content.description}
                                        </p> */}
                                    </div>
                    )
                }
                {activeTab.map(
                    (tab) =>
                        activeTab === tab.id && (
                            <div key={tab.id}>

                                
                            </div>
                        )
                )}
            </div>
        </div>
    );
}
