import React from "react";

const LocalizedContent = ({ lang, uzContent, ruContent, enContent }) => {
    const getContent = () => {
        switch (lang) {
            case 'uz':
                return uzContent;
            case 'ru':
                return ruContent;
            case 'en':
                return enContent;
            default:
                return '';
        }
    };

    return (
        <div
            dangerouslySetInnerHTML={{ __html: getContent() }}
        />
    );
};

export default LocalizedContent;
