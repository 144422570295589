import React, { useCallback } from 'react';
import {useDispatch} from "react-redux";
import { login, logout } from "../../redux/authSlice";
import {useNavigate} from "react-router-dom";

function Navbar() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const LogOut = useCallback(() => {
        dispatch(logout(null));
        navigate("/admintools/index");
    }, [dispatch, navigate]);
    return (
        <div className="bg-white text-white p-3 fixed top-0 left-64 right-0 flex justify-between items-center border-b">
            <h1 className="text-xl font-semibold">Dashboard Header</h1>
            <button className="bg-white text-red-600 px-4 py-2 rounded border-1 border-red-500 hover:bg-gray-600 hover:border-red-300" onClick={() => LogOut()}>
                Chiqish

            </button>
        </div>
    );
}

export default Navbar;
