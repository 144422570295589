import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import { jwtDecode } from "jwt-decode";
import {FiEdit, FiPlus, FiTrash2} from "react-icons/fi";
import CustomModal from "./components/CustomModal";
import {Form, Formik} from "formik";
import CustomInput from "../components/CustomInput";
import Editor from "./components/Editor";
import * as Yup from "yup";
import {useApiRequest} from "../hooks/useRequest";

function Dashboard(props) {
    const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();
    const { token } = useSelector((state) => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [listKafedra, setListKafedra] = useState([]);

    useEffect(() => {
        GetMenuList(8)
    }, [])

    const GetMenuList = async (id) => {
        const response = await getRequest("list-kafedra");
        if(response){
            setListKafedra(response.data)
        }
    }

    const Validation = Yup.object().shape({
        kafedra_name: Yup.string().required('Заполните поле!'),
        kafedra_about: Yup.string().required('Заполните поле!')
    });


    const SaveData = async(values, setValues) => {
        const response = await postRequest("create-kafedra", values);
        if(response)
        {
            GetMenuList(8)
            setShowModal(false);
        }
    }

    const UpdatePost = async (values, setValues) => {

        const response = await putRequest(`edit-kafedra/${postOne?.kafedra_id}`, values);
        if(response)
        {
            setShowModalEdit(false)
            GetMenuList()
        }
    }

    const [base64Data, setBase64Data] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [previewImage, setPreviewImage] = useState(null);
    const [postOne, setPostOne] = useState(null);



    const DeletePost = async (id) => {
        const response = await deleteRequest("delete-kafedra/"+id+"");
        if(response)
        {
            GetMenuList(8)
        }
    }

    const OpenEditModal = async (items) => {

        setPostOne(items)
        setShowModalEdit(true)
    }


    return (
        <div className="p-6">
            <div className={'d-flex flex-row align-items-center justify-content-between'}>
                <h1 className="text-2xl font-semibold">Kafedralar</h1>
                <button className={'btn btn-sm btn-primary'}
                onClick={() =>setShowModal(true)}
                >
                    <FiPlus/>
                </button>
            </div>

            <div className={'mt-3'}>
                <div>
                    <table className={'table table-striped table-hover'}>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Kafedra nomi</th>
                            <th width={100}>#</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            listKafedra && listKafedra.map((items, index) => {
                                return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{items?.kafedra_name}</td>
                                        <td>
                                            <div className={'d-flex gap-3'}>
                                                <button className={'btn btn-sm btn-outline-secondary'}
                                                        onClick={() => OpenEditModal(items)}
                                                ><FiEdit/>
                                                </button>
                                                <button className={'btn btn-sm btn-outline-danger'}
                                                        onClick={() => DeletePost(items?.kafedra_id)}
                                                ><FiTrash2/></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <CustomModal
                show={showModal}
                setShow={setShowModal}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        kafedra_menu_id: 8,
                        kafedra_name: "",
                        kafedra_name_ru: "",
                        kafedra_name_en: "",
                        kafedra_about: "",
                        kafedra_about_ru: "",
                        kafedra_about_en: ""
                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        SaveData(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>


                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Kafedra nomi"}
                                                label={"Kafedra nomi (uz)"}
                                                name={"kafedra_name"}
                                                value={values.kafedra_name}
                                                onChange={handleChange}
                                                error={errors.kafedra_name}
                                                touched={touched.kafedra_name}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Kafedra nomi"}
                                                label={"Kafedra nomi (ru)"}
                                                name={"kafedra_name_ru"}
                                                value={values.kafedra_name_ru}
                                                onChange={handleChange}
                                                error={errors.kafedra_name_ru}
                                                touched={touched.kafedra_name_ru}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Kafedra nomi"}
                                                label={"Kafedra nomi (en)"}
                                                name={"kafedra_name_en"}
                                                value={values.kafedra_name_en}
                                                onChange={handleChange}
                                                error={errors.kafedra_name_en}
                                                touched={touched.kafedra_name_en}
                                            />
                                        </div>


                                    </div>
                                </div>

                                <div className={'py-2'}>

                                    <label>Kafedra haqida uz</label>

                                    <Editor
                                        content={values.kafedra_about}
                                        onChange={(content) =>
                                            setFieldValue("kafedra_about", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Kafedra haqida ru</label>

                                    <Editor
                                        content={values.kafedra_about_ru}
                                        onChange={(content) =>
                                            setFieldValue("kafedra_about_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Kafedra haqida en</label>

                                    <Editor
                                        content={values.kafedra_about_en}
                                        onChange={(content) =>
                                            setFieldValue("kafedra_about_en", content)
                                        }
                                    />

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>

            <CustomModal
                show={showModalEdit}
                setShow={setShowModalEdit}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        kafedra_menu_id: 8,
                        kafedra_name: postOne?.kafedra_name,
                        kafedra_name_ru: postOne?.kafedra_name_ru,
                        kafedra_name_en: postOne?.kafedra_name_en,
                        kafedra_about: postOne?.kafedra_about,
                        kafedra_about_ru: postOne?.kafedra_about_ru,
                        kafedra_about_en: postOne?.kafedra_about_en
                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        UpdatePost(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>


                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Kafedra nomi"}
                                                label={"Kafedra nomi (uz)"}
                                                name={"kafedra_name"}
                                                value={values.kafedra_name}
                                                onChange={handleChange}
                                                error={errors.kafedra_name}
                                                touched={touched.kafedra_name}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Kafedra nomi"}
                                                label={"Kafedra nomi (ru)"}
                                                name={"kafedra_name_ru"}
                                                value={values.kafedra_name_ru}
                                                onChange={handleChange}
                                                error={errors.kafedra_name_ru}
                                                touched={touched.kafedra_name_ru}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Kafedra nomi"}
                                                label={"Kafedra nomi (en)"}
                                                name={"kafedra_name_en"}
                                                value={values.kafedra_name_en}
                                                onChange={handleChange}
                                                error={errors.kafedra_name_en}
                                                touched={touched.kafedra_name_en}
                                            />
                                        </div>


                                    </div>
                                </div>

                                <div className={'py-2'}>

                                    <label>Kafedra haqida uz</label>

                                    <Editor
                                        content={values.kafedra_about}
                                        onChange={(content) =>
                                            setFieldValue("kafedra_about", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Kafedra haqida ru</label>

                                    <Editor
                                        content={values.kafedra_about_ru}
                                        onChange={(content) =>
                                            setFieldValue("kafedra_about_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Kafedra haqida en</label>

                                    <Editor
                                        content={values.kafedra_about_en}
                                        onChange={(content) =>
                                            setFieldValue("kafedra_about_en", content)
                                        }
                                    />

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>

        </div>
    )
}

export default Dashboard;
