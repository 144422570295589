import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import { jwtDecode } from "jwt-decode";
import {FiEdit, FiPlus, FiTrash2} from "react-icons/fi";
import CustomModal from "./components/CustomModal";
import CustomInput from "../components/CustomInput";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import Editor from "./components/Editor";
import QuillEditor from "./components/QuillEditor";
import {useApiRequest} from "../hooks/useRequest";
// import EditorComponent from "./components/EditorComponent";
import CustomEditor from "./components/CustomEditor";

function Dashboard(props) {
    const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();

    const { token } = useSelector((state) => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [subMenuList, setSubMenuList] = useState([]);
    const [postLists, setPostLists] = useState([]);
    const [postOne, setPostOne] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        GetMenuList(10)
        GetPostList()
    }, []);

    const Validation = Yup.object().shape({
        post_content: Yup.string().required('Заполните поле!'),
        post_title: Yup.string().required('Заполните поле!'),
        post_desc: Yup.string().required('Заполните поле!'),
        post_date: Yup.string().required('Заполните поле!'),
        post_image: Yup.string().required('Заполните поле!'),
    });

    const LoginUser = async (values, setValues) => {
        const response = await postRequest("create-post", values);
        if(response)
        {
            GetPostList()
            setShowModal(false);
        }
    }

    const UpdatePost = async (values, setValues) => {

        const response = await putRequest(`edit-post/${postOne?.post_id}`, values);
        if(response)
        {
            setShowModalEdit(false)
            GetPostList()
        }
    }

    const GetMenuList = async (id) => {
        const response = await getRequest("sub-menu-type/"+id+"");
        if(response){
            setSubMenuList(response.data)
        }
    }

    const GetPostList = async (page = 1) => {
        const response = await getRequest("post-list/"+page+"");
        if(response){
            setPostLists(response?.data?.data)
        }
        else{
            setPostLists([])
        }
    }

    const DeletePost = async (id) => {
        const response = await deleteRequest("post/"+id+"");
        if(response)
        {
            GetPostList()
        }
    }

    const OpenEditModal = async (items) => {

        setPostOne(items)
        setPreviewImage(items?.post_image)
        setShowModalEdit(true)
    }

    const [base64Data, setBase64Data] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");

    const handleFileUpload = (event, setFieldValue) => {

        const file = event.target.files[0];

        if (file) {
            setFieldValue("post_old_image", postOne?.post_image || "");
            setPreviewImage(URL.createObjectURL(file));
        }

        if (!file) return;

        setFileName(file.name);
        setFileType(file.type);

        setFieldValue("file_name", file.name)

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setBase64Data(reader.result);
            setFieldValue("post_image", reader.result);
        };

        reader.onerror = (error) => {
            console.error("File could not be read!", error);
        };
    };


    return (
        <div className="p-6">
            <div className={'d-flex flex-row align-items-center justify-content-between'}>
                <h1 className="text-2xl font-semibold">Maqolalar</h1>
                <button className={'btn btn-sm btn-primary'} onClick={() =>setShowModal(true)}>
                    <FiPlus/>
                </button>
            </div>

            <div className={'mt-3'}>
                <div>
                    <table className={'table table-striped table-hover'}>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Maqola mavzusi</th>
                            <th>Maqola sanasi</th>
                            <th>Maqola rasmi</th>
                            <th width={100}>#</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            postLists && postLists.map((items, index) => {
                                return (
                                    <tr key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td>{items.post_title}</td>
                                        <td>{items?.post_date}</td>
                                        <td></td>

                                        <td>
                                            <div className={'d-flex gap-3'}>
                                                <button className={'btn btn-sm btn-outline-secondary'}
                                                onClick={() => OpenEditModal(items)}
                                                ><FiEdit/>
                                                </button>
                                                <button className={'btn btn-sm btn-outline-danger'}
                                                onClick={() => DeletePost(items?.post_id)}
                                                ><FiTrash2/></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        </tbody>
                    </table>
                </div>
            </div>

            <CustomModal
                show={showModal}
                setShow={setShowModal}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        post_content: "",
                        post_content_ru: "",
                        post_content_en: "",
                        post_menu_id: "",
                        post_title: "",
                        post_image: "",
                        post_desc: "",
                        post_title_ru: "",
                        post_title_en: "",
                        post_desc_ru: "",
                        post_desc_en: "",
                        post_date: "",
                        file_name: "",
                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        LoginUser(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <label>Kategoriya tanlang</label>
                                            <select className={'form-select'}
                                             onChange={(e) => setFieldValue("post_menu_id", e.target.value)}
                                            >
                                                <option>---</option>
                                                {
                                                    subMenuList && subMenuList.map((items, index) => {
                                                        return(
                                                            <option key={index + 1} value={items.sub_menu_id}>{items.sub_title_uz}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <label>Maqola asosiy rasmi</label>
                                            <div><input type={"file"} onChange={(e) => handleFileUpload(e, setFieldValue)}/></div>
                                        </div>
                                        <div className={'col-md-4'}>

                                            <CustomInput
                                                placeholder={"Maqola sanasi"}
                                                label={"Maqola sanasi"}
                                                type={"date"}
                                                name={"post_date"}
                                                value={values.post_date}
                                                onChange={handleChange}
                                                error={errors.post_date}
                                                touched={touched.post_date}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mavzusi"}
                                                label={"Maqola mavzusi (uz)"}
                                                name={"post_title"}
                                                value={values.post_title}
                                                onChange={handleChange}
                                                error={errors.post_title}
                                                touched={touched.post_title}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mavzusi"}
                                                label={"Maqola mavzusi (ru)"}
                                                name={"post_title_ru"}
                                                value={values.post_title_ru}
                                                onChange={handleChange}
                                                error={errors.post_title_ru}
                                                touched={touched.post_title_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mavzusi"}
                                                label={"Maqola mavzusi (en)"}
                                                name={"post_title_en"}
                                                value={values.post_title_en}
                                                onChange={handleChange}
                                                error={errors.post_title_en}
                                                touched={touched.post_title_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mazmuni"}
                                                label={"Maqola qisqacha mazmuni (uz)"}
                                                isTextarea={true}
                                                name={"post_desc"}
                                                value={values.post_desc}
                                                onChange={handleChange}
                                                error={errors.post_desc}
                                                touched={touched.post_desc}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mazmuni"}
                                                label={"Maqola qisqacha mazmuni (ru)"}
                                                isTextarea={true}
                                                name={"post_desc_ru"}
                                                value={values.post_desc_ru}
                                                onChange={handleChange}
                                                error={errors.post_desc_ru}
                                                touched={touched.post_desc_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mazmuni"}
                                                label={"Maqola qisqacha mazmuni (en)"}
                                                isTextarea={true}
                                                name={"post_desc_en"}
                                                value={values.post_desc_en}
                                                onChange={handleChange}
                                                error={errors.post_desc_en}
                                                touched={touched.post_desc_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil uz</label>

                                    <Editor
                                        content={values.post_content}
                                        onChange={(content) =>
                                            setFieldValue("post_content", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil ru</label>

                                    <Editor
                                        content={values.content}
                                        onChange={(content) =>
                                            setFieldValue("post_content_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil en</label>

                                    <Editor
                                        content={values.content}
                                        onChange={(content) =>
                                            setFieldValue("post_content_en", content)
                                        }
                                    />

                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>

            {/*Edit modal*/}

            <CustomModal
                show={showModalEdit}
                setShow={setShowModalEdit}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        post_content: postOne?.post_content,
                        post_content_ru: postOne?.post_content_ru,
                        post_content_en: postOne?.post_content_en,
                        post_menu_id: postOne?.post_menu_id,
                        post_title: postOne?.post_title,
                        post_image: postOne?.post_image,
                        post_desc: postOne?.post_desc,
                        post_title_ru: postOne?.post_title_ru,
                        post_title_en: postOne?.post_title_en,
                        post_desc_ru: postOne?.post_desc_ru,
                        post_desc_en: postOne?.post_desc_en,
                        post_date: postOne?.post_date,
                        file_name: "",
                        post_old_image : ""
                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        UpdatePost(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <label>Kategoriya tanlang</label>
                                            <select
                                                className="form-select"
                                                value={values.post_menu_id}
                                                onChange={(e) => setFieldValue("post_menu_id", e.target.value)}
                                            >
                                                <option value="">---</option>
                                                {subMenuList && subMenuList.map((items, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={items.sub_menu_id}
                                                        selected={values.post_menu_id === items.sub_menu_id}
                                                    >
                                                        {items.sub_title_uz}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <div className={'flex items-center justify-between'}>
                                                <div>
                                                    <label>Maqola asosiy rasmi</label>
                                                    <div><input type={"file"}
                                                                onChange={(e) => handleFileUpload(e, setFieldValue)}/>
                                                    </div>
                                                </div>
                                                {
                                                    values.post_old_image ? (
                                                        <img
                                                            src={`${previewImage}`}
                                                            alt="Eski rasm"
                                                            width="100"
                                                            height="100"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`http://localhost:8012/storage/${previewImage}`}
                                                            alt="Eski rasm"
                                                            width="100"
                                                            height="100"
                                                        />
                                                    )
                                                }

                                            </div>


                                        </div>
                                        <div className={'col-md-4'}>

                                            <CustomInput
                                                placeholder={"Maqola sanasi"}
                                                label={"Maqola sanasi"}
                                                type={"date"}
                                                name={"post_date"}
                                                value={values.post_date}
                                                onChange={handleChange}
                                                error={errors.post_date}
                                                touched={touched.post_date}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mavzusi"}
                                                label={"Maqola mavzusi (uz)"}
                                                name={"post_title"}
                                                value={values.post_title}
                                                onChange={handleChange}
                                                error={errors.post_title}
                                                touched={touched.post_title}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mavzusi"}
                                                label={"Maqola mavzusi (ru)"}
                                                name={"post_title_ru"}
                                                value={values.post_title_ru}
                                                onChange={handleChange}
                                                error={errors.post_title_ru}
                                                touched={touched.post_title_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mavzusi"}
                                                label={"Maqola mavzusi (en)"}
                                                name={"post_title_en"}
                                                value={values.post_title_en}
                                                onChange={handleChange}
                                                error={errors.post_title_en}
                                                touched={touched.post_title_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mazmuni"}
                                                label={"Maqola qisqacha mazmuni (uz)"}
                                                isTextarea={true}
                                                name={"post_desc"}
                                                value={values.post_desc}
                                                onChange={handleChange}
                                                error={errors.post_desc}
                                                touched={touched.post_desc}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mazmuni"}
                                                label={"Maqola qisqacha mazmuni (ru)"}
                                                isTextarea={true}
                                                name={"post_desc_ru"}
                                                value={values.post_desc_ru}
                                                onChange={handleChange}
                                                error={errors.post_desc_ru}
                                                touched={touched.post_desc_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Maqola mazmuni"}
                                                label={"Maqola qisqacha mazmuni (en)"}
                                                isTextarea={true}
                                                name={"post_desc_en"}
                                                value={values.post_desc_en}
                                                onChange={handleChange}
                                                error={errors.post_desc_en}
                                                touched={touched.post_desc_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil uz</label>

                                    <Editor
                                        content={values.post_content}
                                        onChange={(content) =>
                                            setFieldValue("post_content", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil ru</label>

                                    <Editor
                                        content={values.post_content_ru}
                                        onChange={(content) =>
                                            setFieldValue("post_content_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil en</label>

                                    <Editor
                                        content={values.post_content_en}
                                        onChange={(content) =>
                                            setFieldValue("post_content_en", content)
                                        }
                                    />

                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>

        </div>
    )
}

export default Dashboard;
