import React from 'react';
import {useSelector} from "react-redux";
import { jwtDecode } from "jwt-decode";

function Dashboard(props) {
    const { token } = useSelector((state) => state.auth);


    return (
        <div className="p-6">
            <h1 className="text-2xl font-semibold">Welcome!</h1>

        </div>
    )
}

export default Dashboard;
