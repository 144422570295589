import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import PageNotFound from "./pages/PageNotFound";
import DavlatRamzlari from "./pages/DavlatRamzlari";
import MurojaatPage from "./pages/MurojaatPage";
import Qabul from "./pages/Qabul";
import AdminIndex from "./admintools/Index";
import DashboardPage from "./admintools/Dashboard";
import Xodimlar from "./admintools/Xodimlar";
import Maqolalar from "./admintools/Maqolalar";
import Sahifalar from "./admintools/Sahifalar";
import Rahbariyat from "./admintools/Rahbariyat";
import Kafedralar from "./admintools/Kafedralar";
import Hamkor from "./admintools/Hamkor";
import Service from "./admintools/Service"
import Havola from "./admintools/Havola";
import AppCss from "./App.css";
import ProtectedRoute from "./ProtectedRoute";
import AdminLayout from "../src/admintools/Layout";
import Statistika from "./admintools/Statistika";
import Tizimlar from "./admintools/Tizimlar";
import Gallery from "./admintools/Gallery";


function App() {
    return (
        <Router>

            <Routes>
                <Route index element={<Navigate to="/uz" replace />} />
                <Route path=":lang" element={<HomePage />} />

                <Route path="/" element={<Layout />}>
                    <Route path=":lang/universitet/:slug" element={<AboutPage />} />
                    <Route path=":lang/kampus/:slug" element={<AboutPage />} />
                    <Route path=":lang/faoliyat/:slug" element={<AboutPage />} />
                    <Route path=":lang/talabalarga/:slug" element={<AboutPage />} />
                    <Route path=":lang/davlat-ramzlari/:slug" element={<DavlatRamzlari />} />
                    <Route path=":lang/contact" element={<ContactPage />} />
                    <Route path=":lang/murojaat" element={<MurojaatPage />} />
                    <Route path=":lang/qabul" element={<Qabul />} />

                    <Route path="*" element={<PageNotFound />} />
                </Route>
                <Route path="admintools/index" element={<AdminIndex />} />
                <Route path="/" element={<AdminLayout />}>

                <Route path="admintools/dashboard" element={
                    <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <DashboardPage />
                    </ProtectedRoute>
                } />

                    <Route path="admintools/xodimlar" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                            <Xodimlar />
                        </ProtectedRoute>
                    } />
                    <Route path="admintools/maqolalar" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                            <Maqolalar />
                        </ProtectedRoute>
                    } />

                    <Route path="admintools/sahifalar" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                            <Sahifalar />
                        </ProtectedRoute>
                    } />

                    <Route path="admintools/rahbariyat" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                            <Rahbariyat />
                        </ProtectedRoute>
                    } />

                    <Route path="admintools/kafedra" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                            <Kafedralar />
                        </ProtectedRoute>
                    } />

                    <Route path="admintools/hamkor" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                            <Hamkor />
                        </ProtectedRoute>
                    } />

                    <Route path="admintools/havola" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                            <Havola />
                        </ProtectedRoute>
                    } />
                     <Route path="admintools/statistika" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                           <Statistika/> 
                        </ProtectedRoute>
                    } />

                     <Route path="admintools/xizmatlar" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                           <Service/> 
                        </ProtectedRoute>
                    } />

                    <Route path="admintools/tizimlar" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                           <Tizimlar/> 
                        </ProtectedRoute>
                    } />

                    <Route path="admintools/gallery" element={
                        <ProtectedRoute allowedRoles={["admin", "user"]}>
                           <Gallery/> 
                        </ProtectedRoute>
                    } />
                    


                </Route>
                <Route path="admintools/unauthorized" element={<h2>Unauthorized Access</h2>} />
            </Routes>
        </Router>
    );
}

export default App;
