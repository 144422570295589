import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import { jwtDecode } from "jwt-decode";
import {FiEdit, FiPlus, FiTrash2} from "react-icons/fi";
import CustomModal from "./components/CustomModal";
import {Form, Formik} from "formik";
import CustomInput from "../components/CustomInput";
import Editor from "./components/Editor";
import * as Yup from "yup";
import {useApiRequest} from "../hooks/useRequest";

function Dashboard(props) {
    const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();
    const { token } = useSelector((state) => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [membersList, setMembersList] = useState([]);
    const [showModalEdit, setShowModalEdit] = useState(false);

    useEffect(() => {
        GetMenuList(8)
    }, [])

    const GetMenuList = async (id) => {
        const response = await getRequest("member-list/"+id+"");
        if(response){
            setMembersList(response.data)
        }
    }

    const Validation = Yup.object().shape({
        member_photo: Yup.string().required('Заполните поле!'),
        member_name: Yup.string().required('Заполните поле!'),
        member_deputy_name: Yup.string().required('Заполните поле!'),
        member_email: Yup.string().required('Заполните поле!'),
        member_phone: Yup.string().required('Заполните поле!'),
        member_address: Yup.string().required('Заполните поле!'),
    });


    const SaveData = async(values, setValues) => {
        const response = await postRequest("create-member", values);
        if(response)
        {
            GetMenuList(8)
            setShowModal(false);
        }
    }

    const [base64Data, setBase64Data] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [previewImage, setPreviewImage] = useState(null);
    const [postOne, setPostOne] = useState(null);



    const handleFileUpload = (event, setFieldValue) => {

        const file = event.target.files[0];

        if (file) {
            setFieldValue("post_old_image", postOne?.member_photo || "");
            setPreviewImage(URL.createObjectURL(file));
        }

        if (!file) return;

        setFileName(file.name);
        setFileType(file.type);

        setFieldValue("file_name", file.name)

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setBase64Data(reader.result);
            setFieldValue("member_photo", reader.result);
        };

        reader.onerror = (error) => {
            console.error("File could not be read!", error);
        };
    };

    const DeletePost = async (id) => {
        const response = await deleteRequest("delete-member/"+id+"");
        if(response)
        {
            GetMenuList(8)
        }
    }

    const OpenEditModal = async (items) => {

        setPostOne(items)
        setPreviewImage(items?.member_photo)
        setShowModalEdit(true)
    }


    const UpdatePost = async (values, setValues) => {

        const response = await putRequest(`edit-member/${postOne?.member_id}`, values);
        if(response)
        {
            setShowModalEdit(false)
            GetMenuList(8)
        }
    }


    return (
        <div className="p-6">
            <div className={'d-flex flex-row align-items-center justify-content-between'}>
                <h1 className="text-2xl font-semibold">Rahbariyat</h1>
                <button className={'btn btn-sm btn-primary'}
                onClick={() =>setShowModal(true)}
                >
                    <FiPlus/>
                </button>
            </div>

            <div className={'mt-3'}>
                <div>
                    <table className={'table table-striped table-hover'}>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>F.I.SH</th>
                            <th>Lavozim</th>
                            <th>Telefon raqam</th>
                            <th width={100}>#</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            membersList && membersList.map((items, index) => {
                                return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{items?.member_name}</td>
                                        <td>{items?.member_deputy_name}</td>
                                        <td>{items?.member_phone}</td>
                                        <td>
                                            <div className={'d-flex gap-3'}>
                                                <button className={'btn btn-sm btn-outline-secondary'}
                                                        onClick={() => OpenEditModal(items)}
                                                ><FiEdit/>
                                                </button>
                                                <button className={'btn btn-sm btn-outline-danger'}
                                                        onClick={() => DeletePost(items?.member_id)}
                                                ><FiTrash2/></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <CustomModal
                show={showModal}
                setShow={setShowModal}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        member_menu_id: 8,
                        member_name: "",
                        member_photo: "",
                        member_deputy_name: "",
                        member_email: "",
                        member_phone: "",
                        member_address: "",
                        member_bio: "",
                        member_function: "",
                        member_name_ru: "",
                        member_name_en: "",
                        member_deputy_name_ru: "",
                        member_deputy_name_en: "",
                        member_bio_ru: "",
                        member_bio_en: "",
                        member_function_ru: "",
                        member_function_en: "",
                        post_old_image: "",
                        file_name: "",
                        member_kafedra_id: 0
                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        SaveData(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <label>Maqola asosiy rasmi</label>
                                            <div><input type={"file"}
                                                        onChange={(e) => handleFileUpload(e, setFieldValue)}/></div>
                                        </div>

                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim FIO"}
                                                label={"Xodim FIO (uz)"}
                                                name={"member_name"}
                                                value={values.member_name}
                                                onChange={handleChange}
                                                error={errors.member_name}
                                                touched={touched.member_name}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim FIO"}
                                                label={"Xodim FIO(ru)"}
                                                name={"member_name_ru"}
                                                value={values.member_name_ru}
                                                onChange={handleChange}
                                                error={errors.member_name_ru}
                                                touched={touched.member_name_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim FIO"}
                                                label={"Xodim FIO (en)"}
                                                name={"member_name_en"}
                                                value={values.member_name_en}
                                                onChange={handleChange}
                                                error={errors.member_name_en}
                                                touched={touched.member_name_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim lavozimi"}
                                                label={"Xodim lavozimi (uz)"}
                                                isTextarea={true}
                                                name={"member_deputy_name"}
                                                value={values.member_deputy_name}
                                                onChange={handleChange}
                                                error={errors.member_deputy_name}
                                                touched={touched.member_deputy_name}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim lavozimi"}
                                                label={"Xodim lavozimi (ru)"}
                                                isTextarea={true}
                                                name={"member_deputy_name_ru"}
                                                value={values.member_deputy_name_ru}
                                                onChange={handleChange}
                                                error={errors.member_deputy_name_ru}
                                                touched={touched.member_deputy_name_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim lavozimi"}
                                                label={"Xodim lavozimi (en)"}
                                                isTextarea={true}
                                                name={"member_deputy_name_en"}
                                                value={values.member_deputy_name_en}
                                                onChange={handleChange}
                                                error={errors.member_deputy_name_en}
                                                touched={touched.member_deputy_name_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Manzil"}
                                                label={"Manzil (uz)"}
                                                name={"member_address"}
                                                value={values.member_address}
                                                onChange={handleChange}
                                                error={errors.member_address}
                                                touched={touched.member_address}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Telefon"}
                                                label={"Telefon (ru)"}
                                                name={"member_phone"}
                                                value={values.member_phone}
                                                onChange={handleChange}
                                                error={errors.member_phone}
                                                touched={touched.member_phone}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Email"}
                                                label={"Email (en)"}
                                                name={"member_email"}
                                                value={values.member_email}
                                                onChange={handleChange}
                                                error={errors.member_email}
                                                touched={touched.member_email}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>

                                    <label>Biografiya uz</label>

                                    <Editor
                                        content={values.member_bio}
                                        onChange={(content) =>
                                            setFieldValue("member_bio", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Biografiya ru</label>

                                    <Editor
                                        content={values.member_bio_ru}
                                        onChange={(content) =>
                                            setFieldValue("member_bio_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Biografiya en</label>

                                    <Editor
                                        content={values.member_bio_en}
                                        onChange={(content) =>
                                            setFieldValue("member_bio_en", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Funksiya vazifalar uz</label>

                                    <Editor
                                        content={values.member_function}
                                        onChange={(content) =>
                                            setFieldValue("member_function", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Funksiya vazifalar ru</label>

                                    <Editor
                                        content={values.member_function_ru}
                                        onChange={(content) =>
                                            setFieldValue("member_function_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Funksiya vazifalar en</label>

                                    <Editor
                                        content={values.member_function_en}
                                        onChange={(content) =>
                                            setFieldValue("member_function_en", content)
                                        }
                                    />

                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>


            <CustomModal
                show={showModalEdit}
                setShow={setShowModalEdit}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        member_menu_id: 8,
                        member_name: postOne?.member_name,
                        member_photo: postOne?.member_photo,
                        member_deputy_name: postOne?.member_deputy_name,
                        member_email: postOne?.member_email,
                        member_phone: postOne?.member_phone,
                        member_address: postOne?.member_address,
                        member_bio: postOne?.member_bio,
                        member_function: postOne?.member_function,
                        member_name_ru: postOne?.member_name_ru,
                        member_name_en: postOne?.member_name_en,
                        member_deputy_name_ru: postOne?.member_deputy_name_ru,
                        member_deputy_name_en: postOne?.member_deputy_name_en,
                        member_bio_ru: postOne?.member_bio_ru,
                        member_bio_en: postOne?.member_bio_en,
                        member_function_ru: postOne?.member_function_ru,
                        member_function_en: postOne?.member_function_en,
                        post_old_image: "",
                        file_name: "",
                        member_kafedra_id: 0,

                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        UpdatePost(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <div className={'flex items-center justify-between'}>
                                                <div>
                                                    <label>Maqola asosiy rasmi</label>
                                                    <div><input type={"file"}
                                                                onChange={(e) => handleFileUpload(e, setFieldValue)}/>
                                                    </div>
                                                </div>
                                                {
                                                    values.post_old_image ? (
                                                        <img
                                                            src={`${previewImage}`}
                                                            alt="Eski rasm"
                                                            width="100"
                                                            height="100"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`http://localhost:8012/storage/${previewImage}`}
                                                            alt="Eski rasm"
                                                            width="100"
                                                            height="100"
                                                        />
                                                    )
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim FIO"}
                                                label={"Xodim FIO (uz)"}
                                                name={"member_name"}
                                                value={values.member_name}
                                                onChange={handleChange}
                                                error={errors.member_name}
                                                touched={touched.member_name}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim FIO"}
                                                label={"Xodim FIO(ru)"}
                                                name={"member_name_ru"}
                                                value={values.member_name_ru}
                                                onChange={handleChange}
                                                error={errors.member_name_ru}
                                                touched={touched.member_name_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim FIO"}
                                                label={"Xodim FIO (en)"}
                                                name={"member_name_en"}
                                                value={values.member_name_en}
                                                onChange={handleChange}
                                                error={errors.member_name_en}
                                                touched={touched.member_name_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim lavozimi"}
                                                label={"Xodim lavozimi (uz)"}
                                                isTextarea={true}
                                                name={"member_deputy_name"}
                                                value={values.member_deputy_name}
                                                onChange={handleChange}
                                                error={errors.member_deputy_name}
                                                touched={touched.member_deputy_name}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim lavozimi"}
                                                label={"Xodim lavozimi (ru)"}
                                                isTextarea={true}
                                                name={"member_deputy_name_ru"}
                                                value={values.member_deputy_name_ru}
                                                onChange={handleChange}
                                                error={errors.member_deputy_name_ru}
                                                touched={touched.member_deputy_name_ru}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Xodim lavozimi"}
                                                label={"Xodim lavozimi (en)"}
                                                isTextarea={true}
                                                name={"member_deputy_name_en"}
                                                value={values.member_deputy_name_en}
                                                onChange={handleChange}
                                                error={errors.member_deputy_name_en}
                                                touched={touched.member_deputy_name_en}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Manzil"}
                                                label={"Manzil (uz)"}
                                                name={"member_address"}
                                                value={values.member_address}
                                                onChange={handleChange}
                                                error={errors.member_address}
                                                touched={touched.member_address}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Telefon"}
                                                label={"Telefon (ru)"}
                                                name={"member_phone"}
                                                value={values.member_phone}
                                                onChange={handleChange}
                                                error={errors.member_phone}
                                                touched={touched.member_phone}
                                            />
                                        </div>
                                        <div className={'col-md-4'}>
                                            <CustomInput
                                                placeholder={"Email"}
                                                label={"Email (en)"}
                                                name={"member_email"}
                                                value={values.member_email}
                                                onChange={handleChange}
                                                error={errors.member_email}
                                                touched={touched.member_email}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'py-2'}>

                                    <label>Biografiya uz</label>

                                    <Editor
                                        content={values.member_bio}
                                        onChange={(content) =>
                                            setFieldValue("member_bio", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Biografiya ru</label>

                                    <Editor
                                        content={values.member_bio_ru}
                                        onChange={(content) =>
                                            setFieldValue("member_bio_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Biografiya en</label>

                                    <Editor
                                        content={values.member_bio_en}
                                        onChange={(content) =>
                                            setFieldValue("member_bio_en", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Funksiya vazifalar uz</label>

                                    <Editor
                                        content={values.member_function}
                                        onChange={(content) =>
                                            setFieldValue("member_function", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Funksiya vazifalar ru</label>

                                    <Editor
                                        content={values.member_function_ru}
                                        onChange={(content) =>
                                            setFieldValue("member_function_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Funksiya vazifalar en</label>

                                    <Editor
                                        content={values.member_function_en}
                                        onChange={(content) =>
                                            setFieldValue("member_function_en", content)
                                        }
                                    />

                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>

        </div>
    )
}

export default Dashboard;
