import { NavLink } from "react-router-dom";

function Sidebar() {
    return (
        <div className="w-64 h-screen bg-white border-r border-r-gray-200 p-3 fixed">
            <h2 className="text-xl font-semibold px-4">Dashboard</h2>
            <nav className="space-y-2 mt-3">
                {[
                    { path: "/admintools/rahbariyat", label: "Rahbariyat" },
                    { path: "/admintools/xodimlar", label: "Xodimlar" },
                    { path: "/admintools/maqolalar", label: "Maqolalar" },
                    { path: "/admintools/sahifalar", label: "Sahifalar" },
                    { path: "/admintools/kafedra", label: "Kafedralar" },
                    { path: "/admintools/hamkor", label: "Hamkorlar"},
                    { path: "/admintools/havola", label: "Foydali havolalar"},
                    { path: "/admintools/statistika", label: "Statistika"},
                    { path: "/admintools/xizmatlar", label: "Interaktiv xizmatlar"},
                    { path: "/admintools/tizimlar", label: "Axborot tizimlari"},
                    { path: "/admintools/gallery", label: "Galereya"},


                ].map((item) => (
                    <NavLink
                        key={item.path}
                        to={item.path}
                        className={({ isActive }) =>
                            `text-gray-600 block py-2 px-4 text-decoration-none rounded hover:bg-gray-700 hover:text-white active:bg-gray-700 focus:text-white ${
                                isActive ? "bg-gray-700 text-white" : ""
                            }`
                        }
                    >
                        {item.label}
                    </NavLink>
                ))}
            </nav>
        </div>
    );
}

export default Sidebar;
