import {RiFacebookCircleFill, RiInstagramFill, RiSmartphoneLine, RiYoutubeFill} from "react-icons/ri";
import {BsEyeglasses} from "react-icons/bs";
import React, {useEffect} from "react";
import Flag from '../images/flag.png'
import Gerb from '../images/gerb.png'
import Melody from '../images/melody.png'
import {NavLink, useNavigate, useParams} from "react-router-dom";


function Header() {
    const { lang = "uz" } = useParams();
    const navigate = useNavigate();
    const handleChangeLanguage = (newLang) => {
        const currentPath = window.location.pathname.split('/').slice(2).join('/');
        navigate(`/${newLang}/${currentPath}`);
    };

    useEffect(() => {

    }, [])

    return (
        <header className="bg-main text-white py-2">
            <div className="container">
                <div className="row align-items-center">
                    {/* Birinchi ustun: ijtimoiy tarmoqlar va email */}
                    <div className="col-md-4">
                        <div className="d-flex align-items-center gap-3">
                            <a href="https://instagram.com" className="text-white">
                                <RiInstagramFill size={26}/>
                            </a>
                            <a href="https://facebook.com" className="text-white">
                                <RiFacebookCircleFill size={26}/>
                            </a>
                            <a href="https://youtube.com" className="text-white">
                                <RiYoutubeFill size={26}/>
                            </a>
                            <span>Elektron pochta: info@jtsu.uz</span>
                        </div>
                    </div>

                    {/* Ikkinchi ustun: davlat ramzlari va qo'shimcha sahifalar */}
                    <div className="col-md-4 text-center">
                        <ul className="list-unstyled d-flex gap-3 mb-0">
                            <li className="d-flex gap-2">
                                <NavLink to="/davlat-ramzlari/bayroq">
                                    <img src={Flag} alt="" style={{width: 25}}/>
                                </NavLink>
                                <NavLink to="/davlat-ramzlari/gerb">
                                    <img src={Gerb} alt="" style={{width: 26}}/>
                                </NavLink>
                                <NavLink to="/davlat-ramzlari/madhiya">
                                    <img src={Melody} alt="" style={{width: 25}}/>
                                </NavLink>
                            </li>
                            <li className="mx-2">
                                <a href="#harakatlar-strategiyasi" className="text-white text-decoration-none">
                                    Harakatlar strategiyasi
                                </a>
                            </li>
                            <li>
                                <a href="#sayt-xaritasi" className="text-white text-decoration-none">
                                    Sayt xaritasi
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Uchinchi ustun: tugmalar */}
                    <div className="col-md-4 text-center text-md-end">
                        <div className="d-flex justify-content-end gap-3">
                            <button className="btn btn-link text-white">
                                <RiSmartphoneLine size={26}/>
                            </button>
                            <button className="btn btn-link text-white">
                                <BsEyeglasses size={26}/>
                            </button>
                            <div className="language-switcher mt-2 flex-row gap-3">
                                {
                                    lang === "uz" ? (
                                        <button className={'mx-2'}
                                                onClick={() => handleChangeLanguage('uz')}>O'z</button>
                                    ) : lang === "ru" ? (
                                        <button onClick={() => handleChangeLanguage('ru')}>Рус</button>
                                    ) : lang === "en" ? (
                                        <button className={'mx-2'}
                                                onClick={() => handleChangeLanguage('en')}>Eng</button>
                                    ) : <></>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default Header;
