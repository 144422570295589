import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import { jwtDecode } from "jwt-decode";
import {FiEdit, FiPlus, FiTrash2} from "react-icons/fi";
import CustomModal from "./components/CustomModal";
import {Form, Formik} from "formik";
import CustomInput from "../components/CustomInput";
import Editor from "./components/Editor";
import * as Yup from "yup";
import {useApiRequest} from "../hooks/useRequest";

function Dashboard(props) {
    const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();

    const { token } = useSelector((state) => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [subMenuList, setSubMenuList] = useState([]);
    const [postLists, setPostLists] = useState([]);
    const [postOne, setPostOne] = useState(null);


    useEffect(() => {
        GetMenuList(1)
        GetPostList()
    }, []);

    const GetMenuList = async (id) => {
        const response = await getRequest("sub-menu-type/"+id+"");
        if(response){
            setSubMenuList(response.data)
        }
    }

    const GetPostList = async (page = 1) => {
        const response = await getRequest("page-post-list");
        if(response){
            setPostLists(response?.data)
        }
        else{
            setPostLists([])
        }
    }

    const Validation = Yup.object().shape({
        page_content: Yup.string().required('Заполните поле!'),
        page_menu_id: Yup.string().required('Заполните поле!')
    });

    const SavePost = async (values, setValues) => {

        const response = await postRequest("create-page-post", values);
        if(response)
        {
            setShowModal(false);
        }
    }

    const handleFileUpload = () => {

    }

    const LargeTextComponent = ({ text, maxLength = 100 }) => {

        const shortenedText = text.substring(0, maxLength);

        return (
            <div>
                <p>{shortenedText}</p>
            </div>
        );
    };

    const OpenEditModal = async (items) => {

        setPostOne(items)
        setShowModalEdit(true)
    }

    const UpdatePost = async (values, setValues) => {

        const response = await putRequest(`edit-page-post/${postOne?.page_id}`, values);
        if(response)
        {
            setShowModalEdit(false)

            GetPostList()
        }
    }

    const DeletePost = async (id) => {
        const response = await deleteRequest(`page/${id}`);
        if(response)
        {
            GetPostList()
        }
    }
    

    return (
        <div className="p-6">
            <div className={'d-flex flex-row align-items-center justify-content-between'}>
                <h1 className="text-2xl font-semibold">Xodimlar</h1>
                <button className={'btn btn-sm btn-primary'} onClick={() => setShowModal(true)}>
                    <FiPlus/>
                </button>
            </div>

            <div className={'mt-3'}>
                <div>
                    <table className={'table table-striped table-hover'}>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Menu</th>
                            <th>Batafsil</th>
                            <th width={100}>#</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            postLists && postLists.map((items, index) => {
                                return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{items?.sub_title_uz}</td>
                                        <td>
                                            <LargeTextComponent text={items?.page_content}/>
                                        </td>
                                        <td>
                                            <div className={'d-flex gap-3'}>
                                                <button className={'btn btn-sm btn-outline-secondary'}
                                                        onClick={() => OpenEditModal(items)}
                                                ><FiEdit/>
                                                </button>
                                                <button className={'btn btn-sm btn-outline-danger'}
                                                        onClick={() => DeletePost(items?.page_id)}
                                                ><FiTrash2/></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>


            <CustomModal
                show={showModal}
                setShow={setShowModal}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        page_content: "",
                        page_content_ru: "",
                        page_content_en: "",
                        page_menu_id: ""
                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        SavePost(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <div className={'flex items-center gap-3'}>
                                                <div>
                                                    <label>Kategoriya tanlang</label>
                                                </div>
                                                <div>
                                                    {errors && touched ? (
                                                        <div style={{marginLeft: 5}}>
                                                            <div style={{
                                                                color: 'red',
                                                                fontSize: 9
                                                            }}>{errors.page_menu_id}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <select className={'form-select'}
                                                    onChange={(e) => setFieldValue("page_menu_id", e.target.value)}
                                            >
                                                <option>---</option>
                                                {
                                                    subMenuList && subMenuList.map((items, index) => {
                                                        return (
                                                            <option key={index + 1} value={items.sub_menu_id}>{items.sub_title_uz}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                    </div>
                                </div>


                                <div className={'py-2'}>


                                    <div className={'flex items-center gap-3'}>
                                        <div>
                                            <label>Batafsil uz</label>
                                        </div>
                                        <div>
                                            {errors && touched ? (
                                                <div style={{marginLeft: 5}}>
                                                    <div style={{
                                                        color: 'red',
                                                        fontSize: 9
                                                    }}>{errors.page_content}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <Editor
                                        content={values.page_content}
                                        onChange={(content) =>
                                            setFieldValue("page_content", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil ru</label>

                                    <Editor
                                        content={values.page_content_ru}
                                        onChange={(content) =>
                                            setFieldValue("page_content_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil en</label>

                                    <Editor
                                        content={values.page_content_en}
                                        onChange={(content) =>
                                            setFieldValue("page_content_en", content)
                                        }
                                    />

                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>

            <CustomModal
                show={showModalEdit}
                setShow={setShowModalEdit}
                title=""
                fullscreen={true}
            >
                <Formik
                    initialValues={{
                        page_content: postOne?.page_content,
                        page_content_ru: postOne?.page_content_ru,
                        page_content_en: postOne?.page_content_en,
                        page_menu_id: postOne?.page_menu_id,
                    }}
                    validationSchema={Validation}
                    onSubmit={(values, {setValues}) => {
                        UpdatePost(values, setValues)
                    }}

                >
                    {({
                          handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                      }) => (
                        <Form>
                            <div>

                                <div className={'py-2 d-flex align-items-end justify-end'}>
                                    <button className={'btn btn-outline-primary'}>Saqlash</button>
                                </div>

                                <div className={'py-2'}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <div className={'flex items-center gap-3'}>
                                                <div>
                                                    <label>Kategoriya tanlang</label>
                                                </div>
                                                <div>
                                                    {errors && touched ? (
                                                        <div style={{marginLeft: 5}}>
                                                            <div style={{
                                                                color: 'red',
                                                                fontSize: 9
                                                            }}>{errors.page_menu_id}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <select className={'form-select'}
                                                    value={values.page_menu_id}
                                                    onChange={(e) => setFieldValue("page_menu_id", e.target.value)}
                                            >
                                                <option value="">---</option>
                                                {subMenuList && subMenuList.map((items, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={items.sub_menu_id}
                                                        selected={values.page_menu_id === items.sub_menu_id}
                                                    >
                                                        {items.sub_title_uz}
                                                    </option>
                                                ))}

                                            </select>
                                        </div>

                                    </div>
                                </div>


                                <div className={'py-2'}>


                                    <div className={'flex items-center gap-3'}>
                                        <div>
                                            <label>Batafsil uz</label>
                                        </div>
                                        <div>
                                            {errors && touched ? (
                                                <div style={{marginLeft: 5}}>
                                                    <div style={{
                                                        color: 'red',
                                                        fontSize: 9
                                                    }}>{errors.page_content}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <Editor
                                        content={values.page_content}
                                        onChange={(content) =>
                                            setFieldValue("page_content", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil ru</label>

                                    <Editor
                                        content={values.page_content_ru}
                                        onChange={(content) =>
                                            setFieldValue("page_content_ru", content)
                                        }
                                    />

                                </div>

                                <div className={'py-2'}>

                                    <label>Batafsil en</label>

                                    <Editor
                                        content={values.page_content_en}
                                        onChange={(content) =>
                                            setFieldValue("page_content_en", content)
                                        }
                                    />

                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>

        </div>
    )
}

export default Dashboard;
