import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

function ProtectedRoute({ children, allowedRoles }) {
    const { token } = useSelector((state) => state.auth);




    if (token === undefined) {
        return <div>Loading...</div>;
    }


    if (!token) {
        return <Navigate to="/admintools/index" />;
    }

    const decoded = jwtDecode(token);


    if (!allowedRoles.includes(decoded?.role)) {
        return <Navigate to="/unauthorized" />;
    }

    return children;
}

export default ProtectedRoute;
