import {useEffect} from "react";
import {useParams} from "react-router-dom";

function PageNotFound() {
    const { slug } = useParams();
    useEffect(() => {

    }, []);
    return (
        <>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <p className={'alert alert-warning'}>
                            Sahifa yuklanmoqda
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;
