import Layout from "../components/Layout";
import {Link, useParams} from "react-router-dom";

function DavlatRamzlari() {
    const { slug } = useParams();
    return (
        <>

            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-12'}>


                        {
                            slug === 'bayroq' && (
                                <>
                                    <p><img src="https://static.tuit.uz/uploads/1/720__USshDt-rA6KZZg6g5U5yogmh5FlXsKDz.jpg"
                                            width="700" height="400"/></p>
                                    <div className="tt_content col-xs-12 col-md-12">
                                        <div className="tt_contxt">
                                            <div className="page-content">
                                                <p><Link target="_blank"
                                                         href={"http://www.lex.uz/pages/GetAct.aspx?lact_id=1716195"}>
                                                    "O‘zbekiston Respublikasi Davlat bayrog‘i
                                                    to‘g‘risida"gi

                                                </Link> qonun 1991 yil 18-noyabrda O‘zbekiston
                                                    Respublikasi Oliy Kengashining navbatdan tashqari o‘tkazilgan VII
                                                    sessiyasida qabul qilingan.</p>
                                                <p className={'text-justify py-2'}>Davlat bayrog‘i va uning ramzi bugungi
                                                    O‘zbekiston sarhadida qadimda mavjud bo‘lgan davlatlar bilan tarixan
                                                    bog‘liqligini anglatadi hamda respublikaning milliy-madaniy an’analarini
                                                    o‘zida mujassamlashtiradi.</p>
                                                <p className={'text-justify py-2'}>Bayroqdagi moviy rang tiriklik mazmuni aks
                                                    etgan mangu osmon va obihayot ramzi. Timsollar tilida bu - yaxshilikni,
                                                    donishmandlikni, halollikni, shon-shuhrat va sadoqatni bildiradi.
                                                    Binobarin, Amir Temur davlati bayrog‘ining rangi ham moviy rangda
                                                    edi.</p>
                                                <p className={"text-justify py-2"}>Bayroqdagi oq rang - muqaddas tinchlik ramzi
                                                    bo‘lib, u kun charog‘onligi va koinot yoritkichlari bilan uyg‘unlashib
                                                    ketadi. Oq rang – poklik, beg‘uborlik, soflikni, orzu va hayollar
                                                    tozaligi, ichki go‘zallikka intilishning timsoli.</p>
                                                <p className={"text-justify py-2"}>Yashil rang – tabiatning yangilanish ramzi. U
                                                    ko‘pgina xalqlarda navqironlik, umid va shodumonlik timsoli
                                                    hisoblanadi.</p>
                                                <p className={"text-justify py-2"}>Qizil chiziqlar – vujudimizda jo‘shib
                                                    oqayotgan hayotiy qudrat irmoqlarini anglatadi.</p>
                                                <p className={"text-justify py-2"}>Navqiron yarim oy tasviri bizning tarixiy
                                                    an’analarimiz bilan bog‘liq. Ayni paytda u qo‘lga kiritilgan
                                                    mustaqilligimiz ramzi ham.</p>
                                                <p className={"text-justify py-2"}>Yulduzlar barcha uchun ruhoniy, ilohiy timsol
                                                    sanalgan. O‘zbekiston Respublikasi Davlat bayrog‘idagi 12 yulduz tasviri
                                                    ham tarixiy an’analarimiz, qadimgi yilnomamizga bevosita aloqador.
                                                    Bizning o‘n ikki yulduzga bo‘lgan e’tiborimiz O‘zbekiston sarhadidagi
                                                    qadimgi davlatlar ilmiy tafakkurida nujum ilmi taraqqiy etganligi bilan
                                                    ham izohlanadi.</p>
                                                <p className={"text-justify py-2"}>Davlat bayrog‘imizdagi 12 yulduz tasvirini
                                                    o‘zbek xalqi madaniyatining qadimiyligi, uning komillikka, o‘z
                                                    tuprog‘ida saodatga intilishi ramzi sifatida tushunish lozim.</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                            slug === "gerb" && (
                                <>
                                    <p><img src="https://static.tuit.uz/uploads/1/NMsF85GsPyLwB0CUpwJlKciNDvvS86Tm.jpg"
                                            width="500" height="508"/></p>
                                    <div className="tt_contxt">
                                        <div className="page-content">
                                            <p className={"text-justify py-2"}><Link target="_blank" rel="noopener noreferrer"
                                                                                     href={"http://www.lex.uz/pages/GetAct.aspx?lact_id=17260"}><Link href={"#"}
                                                                                                                                                      target="_blank" rel="noopener">"O‘zbekiston Respublikasi Davlat gerbi
                                                to‘g‘risida"gi</Link></Link> Qonun 1992 yil 2 iyulda O‘zbekiston Respublikasi Oliy
                                                Kengashining X sessiyasida qabul qilingan.</p>
                                            <p className={"text-justify py-2"}>O‘zbekiston Respublikasining Davlat gerbi
                                                gullagan vodiy uzra charaqlab turgan quyosh tasviridan hamda so‘l tomonida
                                                bug‘doy boshoqlari, o‘ng tomonida ochilgan paxta chanoqlari suvrati
                                                tushirilgan chambardan iborat.</p>
                                            <p className={"text-justify py-2"}>Gerbning yuqori qismida respublika jipsligining
                                                ramzi sifatida sakkiz qirrali yulduz tasvirlangan: sakkiz qirra ichida
                                                joylashgan yarim oy va yulduz musulmonlarning qutlug‘ ramzidir.</p>
                                            <p className={"text-justify py-2"}>Gerbning markazida himmat, olijanoblik va
                                                fidoyilik timsoli bo‘lgan afsonaviy Xumo qushi qanotlarini yozib turibdi.
                                                Ushbu ramz va timsollar xalqimizning tinchlik, yaxshilik, baxt-saodat,
                                                farovonlik yo‘lidagi orzu-umidlarini ifodalaydi.</p>
                                            <p className={"text-justify py-2"}>Gerbning pastki qismida respublika Davlat
                                                bayrog‘ini ifoda etuvchi chambar lentasining bandiga "O‘zbekiston” deb yozib
                                                qo‘yilgan.</p>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                            slug === "madhiya" && (
                                <>
                                    <div className="tt_contxt">
                                        <div className="page-content">
                                            <p className={"text-justify py-2"}><Link target="_blank" rel="noopener noreferrer"
                                                                                     href={"http://www.lex.uz/pages/getpage.aspx?lact_id=38608"}>"O'zbekiston
                                                Respublikasining davlat madhiyasi to'g'risida"gi</Link> Qonun&nbsp;<br/>1992-yil
                                                10-dekabrda O'zbekiston Respublikasi Oliy Kengashining<br/>o'n birinchi
                                                sessiyasida qabul qilingan</p>
                                            <p className={"text-left"}><Link target="_blank" rel="noopener noreferrer"
                                                                             href={"https://uz.wikipedia.org/wiki/Mutal_Burhonov"}><strong>Mutal
                                                Burhonov</strong></Link><strong> </strong>musiqasi<br/><Link target="_blank"
                                                                                                             rel="noopener noreferrer"
                                                                                                             href={"https://uz.wikipedia.org/wiki/Abdulla_Oripov"}><strong>Abdulla
                                                Oripov</strong></Link><strong> </strong>so'zi</p>
                                            <p className={"py-3"}>Serquyosh hur o'lkam, elga baxt, najot,<br/>Sen o'zing do'stlarga yo'ldosh,
                                                mehribon!<br/>Yashnagay to abad ilmu fan, ijod,<br/>Shuhrating porlasin toki
                                                bor jahon!</p>
                                            <p className="">&nbsp;</p>
                                            <p>Oltin bu vodiylar - jon O'zbekiston,<br/>Ajdodlar mardona ruhi senga
                                                yor!<br/>Ulug' xalq qudrati jo'sh urgan zamon,<br/>Olamni mahliyo aylagan
                                                diyor!</p>
                                            <p className="">&nbsp;</p>
                                            <p>Bag'ri keng o'zbekning o'chmas iymoni,<br/>Erkin, yosh avlodlar senga zo'r
                                                qanot!<br/>Istiqlol mash'ali tinchlik posboni,<br/>Xaqsevar, ona yurt, mangu
                                                bo'l obod!</p>
                                            <p className="">&nbsp;</p>
                                            <p>Oltin bu vodiylar - jon O'zbekiston,<br/>Ajdodlar mardona ruhi senga
                                                yor!<br/>Ulug' xalq qudrati jo'sh urgan zamon,<br/>Olamni mahliyo aylagan
                                                diyor!</p>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>

        </>
    )
}

export default DavlatRamzlari