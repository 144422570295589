"use client";
import Logo from "../images/logo.png";
import {LuSquareArrowUpRight} from "react-icons/lu";
import React, {useEffect, useState} from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import {useApiRequest} from "../hooks/useRequest";



function LogoMenuSection(props) {
    const { lang = "uz" } = useParams();
    const { postRequest, loading, getRequest } = useApiRequest();

    const [activeDropdown, setActiveDropdown] = useState(null);

    const handleMouseEnter = (menu) => {
        setActiveDropdown(menu);

    };

    const handleMouseLeave = () => {
        setActiveDropdown(null);
    };

    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        GetMainMenu()
    },[])

    const GetMainMenu = async () => {
        const response = await getRequest("menu", {}, false);
        if(response){
            setMenuList(response?.data)
        }
    }

    return (
        <div className={'container pt-10'}>

            <div className="flex flex-wrap items-center justify-between gap-6">
                <div>
                    <Link to ='/'>
                    <img src={Logo} alt="Logo" width={279} height={59}/>
                    </Link>
                </div>
                <div className="flex-1 items-center">
                    <div className={'pt-1'}>
                        <div
                            className={'text-gray-500 italic border-l-4 border-gray-300 pl-4 my-2 text-base leading-relaxed'}>
                            “Agar mendan sizni nima qiynaydi?” deb so‘rasangiz,
                            farzandlarimizning ta’lim va tarbiyasi deb javob beraman.
                        </div>
                        <div className={'flex justify-end px-6 absolute'}>
                            Sh.Mirziyoyev
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-end gap-6">
                    <Link to={`/${lang}/murojaat`} className="text-black text-decoration-none border-2 py-2 px-5 rounded-xl flex items-center gap-3">
                        <span className="text-black text-decoration-none">Virtual qabulxona</span>
                        <span className="text-white">
                <LuSquareArrowUpRight size={25}/>
            </span>
                    </Link>
                    <div>
                        <div>Ishonch telefonlari</div>
                        <div className="font-bold">+99870-717-17-76</div>
                    </div>
                </div>
            </div>

            <div className={'mt-5'}>

                <nav className="navbar">
                    <div className="navbar-container">


                        <ul className="menu">

                            <li>
                                <NavLink to="/">Bosh sahifa</NavLink>
                            </li>

                            {
                                menuList && menuList.map((menu, index) => {
                                    return (
                                        <li
                                        ke
                                            className="dropdown"
                                            onMouseEnter={() => handleMouseEnter(menu?.main_slug)}
                                            onMouseLeave={handleMouseLeave}
                                        >

                                            <NavLink to="#">
                                                {menu?.main_menu_uz}
                                                {
                                                    menu?.submenu.length > 0 &&
                                                    <span className="chevron-main"></span>
                                                }

                                            </NavLink>

                                            {activeDropdown === menu?.main_slug && (
                                                <ul className="dropdown-menus">
                                                    {menu?.submenu.map((sub, sindex) => (
                                                        <li key={sindex}>
                                                            <NavLink to={`${lang}/${sub?.submenu_slug}`}>
                                                                {sub?.submenu_title_uz}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}


                                        </li>
                                    )
                                })
                            }

                            <li>
                                <NavLink to={`${lang}/qabul`}>Qabul</NavLink>
                            </li>


                            <li>
                                <NavLink to={`${lang}/contact`}>Bog'lanish</NavLink>

                            </li>

                        </ul>
                    </div>
                </nav>


                <div className="md:hidden">
                    <button className="text-gray-800 focus:outline-none">

                        &#9776;
                    </button>
                </div>
            </div>

        </div>
    )
}

export default LogoMenuSection;
