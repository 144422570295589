import FooterLogo from "../images/footer_logo.png";
import {RiFacebookCircleFill, RiInstagramFill, RiYoutubeFill} from "react-icons/ri";
import {LuSquareArrowUpRight} from "react-icons/lu";
import {FiMapPin} from "react-icons/fi";
import React from "react";

function Footer() {
    return (
        <footer>
            <div className={'footer'}>
                <div className={'container mx-auto px-4 lg:max-w-[80%] py-10'}>
                    <div className={'grid grid-cols-3'}>
                        <div>
                            <img src={FooterLogo} alt={""}/>
                            <div className={'mt-6'}>
                                <div className="text-white text-center rounded">
                                    <div className={'flex gap-3'}>
                                        <RiInstagramFill size={26}/>
                                        <RiFacebookCircleFill size={26}/>
                                        <RiYoutubeFill size={26}/>

                                    </div>
                                </div>
                                <div className={'mt-6'}>
                                    <p className={'text-white'}>Elektron pochta: info@jtsu.uz</p>
                                </div>
                                <div className={'mt-6'}>
                                    <p className={'text-white'}>Ishonch telefonlari</p>
                                    <h4 className={'text-xl font-bold text-white'}>+99870-717-17-76</h4>
                                </div>
                                <div className={'mt-6'}>
                                    <button className={'border-2 py-2 px-5 rounded-xl flex items-center gap-3'}>
                                        <span className={'text-white'}>Virtual qabulxona</span>
                                        <span className={'text-white'}>
                                        <LuSquareArrowUpRight size={25}/>
                                     </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h4 className={'font-bold text-white text-xl'}>Universitet</h4>
                            <ul className={'footer_menu'}>
                                <li>
                                    Universitet kengashi
                                </li>
                                <li>
                                    Markaz va bo'limlar
                                </li>
                                <li>Nizom va qoidalar</li>
                                <li>Universitet tarixi</li>
                            </ul>
                            <div className={'mt-6'}>
                                <h4 className={'font-bold text-white text-xl'}>Matbuot xizmati</h4>
                                <ul className={'footer_menu'}>
                                    <li>
                                        Xalqaro uchrashuvlar
                                    </li>
                                    <li>
                                        Yangiliklar
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div>
                            <h4 className={'font-bold text-white text-xl'}>Talabalar</h4>
                            <ul className={'footer_menu'}>
                                <li>
                                    Bakalavriat
                                </li>
                                <li>
                                    Magistratura
                                </li>
                                <li>Dars jadvali</li>
                                <li>Xorijiy talabalar uchun</li>
                            </ul>
                            <div className={'mt-6'}>
                                <h4 className={'font-bold text-white text-xl'}>Faoliyat</h4>
                                <ul className={'footer_menu'}>
                                    <li>
                                        Madaniy-ma'rifiy faoliyat
                                    </li>
                                    <li>
                                        O’quv jarayonlari
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className={'footer_bottom'}>
                        <div className={'grid grid-cols-3'}>
                            <div className={'col-span-2'}>
                                <p className={'text-white text-sm'}>Barcha huquqlar himoyalangan. Saytdan olingan
                                    ma'lumotlar chop etilganda veb-saytga havola qilish majburiy.</p>
                                <p className={'text-white mt-1 font-bold'}>Rasmiy veb sayt O'zbekiston Davlat
                                    Jismoniy-tarbiya va sport universiteti qoshidagi Raqamli texnologiyalar va "SPORT
                                    IT" markazi tomonidan ishlab chiqilgan</p>
                            </div>
                            <div>
                                <p className={'text-white text-sm flex items-center gap-3'}>
                                    <FiMapPin size={20}/>
                                    Toshkent viloyati, Chirchiq shahri, Sportchilar ko'chasi, 19-uy
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    )
}

export default Footer;