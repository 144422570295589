import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { Outlet } from "react-router-dom";
function Layout({ children }) {
    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 ml-64">
                <Navbar/>
                <div className="mt-16 p-6">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Layout;
