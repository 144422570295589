import React, { useEffect, useState } from 'react';
import { useApiRequest } from "../hooks/useRequest";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersCarousel = () => {
 const { postRequest, getRequest, putRequest, deleteRequest, loading } = useApiRequest();
const [partners, setListPartners] = useState([]);
     useEffect(() => {
        getPartners()
      }, [])
    
      const getPartners = async () => {
        const response = await getRequest("partners");
        if (response) {
            setListPartners(response.data)
        }
      }
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    return (
        <div className="max-w-12xl mx-auto">

            <Slider {...settings}>
                {partners.map((partner) => (
                    <div
                        key={partner.id}
                        className="flex items-center gap-4"
                    >
                        <div className="w-full">
                            <div className={'bg-white p-2 gap-4 rounded w-[90%]'}>
                                <div className={'text-center flex flex-col items-center border p-2 rounded-xl'}>
                                    <img
                                        src={`https://api.uzdjtsu.uz/storage/${partner?.image}`} 
                                        alt={partner.name_uz}
                                        className="w-full h-full object-cover rounded-xl"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default PartnersCarousel;
